import React from "react";
import { useDisclosure } from "@chakra-ui/react";
import { IoMdAdd } from "react-icons/io";
import { CgNotes } from "react-icons/cg";
import { IoSettings } from "react-icons/io5";
import { BiSolidUserCircle } from "react-icons/bi";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import {
  FaRegCreditCard,
  FaUserTie,
  FaShoppingCart,
  FaUser,
} from "react-icons/fa";
import { SlCalender } from "react-icons/sl";
import { useNavigate, useLocation } from "react-router-dom";

const buttons = [
  {
    name: "My Profile",
    icon: FaUser,
  },
  {
    name: "Data Repository",
    icon: FaShoppingCart,
  },
  {
    name: "Statistics & Analysis",
    icon: CgNotes,
  },
  {
    name: "Subscription Plans",
    icon: SlCalender,
  },
  {
    name: "Payment & Billing",
    icon: FaRegCreditCard,
  },
  {
    name: "Account Settings",
    icon: IoSettings,
  },
  {
    name: "Contact Support",
    icon: FaUserTie,
  },
];

const ProfileMenu = () => {
  const UserName = localStorage.getItem("UserName");
  const navigate = useNavigate();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const location = useLocation();

  const signOut = () => {
    localStorage.clear();
    if (location.pathname === "/") {
      console.log(location.pathname, "logout");
      window.location.reload();
    } else {
      navigate("/");
    }
  };

  return (
    <Menu isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <MenuButton>
        <button className="flex justify-center items-center gap-2 text-[16px] text-white bg-[#770737] rounded-2xl px-4 py-1">
          {UserName}
          {isOpen ? (
            <MdKeyboardArrowUp className="w-6 h-6 text-white" />
          ) : (
            <MdKeyboardArrowDown className="w-6 h-6 text-white" />
          )}
        </button>
      </MenuButton>
      <MenuList
        borderRadius={"35px"}
        backgroundColor={"#F6F6F6"}
        padding={0}
        className="max-w-md flex-auto overflow-hidden rounded-3xl text-sm leading-6 shadow-lg ring-1 ring-gray-900/5"
      >
        <div className="bg-[#770737] h-14 text-Poppins flex justify-center items-center">
          <span className="text-[16px] text-white">{UserName}</span>
        </div>
        <div className="p-4 flex flex-col justify-center">
          {buttons.map((item) => (
            <MenuItem
              backgroundColor={"#F6F6F6"}
              key={item.name}
              className="flex gap-x-6 rounded-lg p-4"
            >
              <button className="flex gap-3 sm:px-2 justify-center rounded-xl text-[#263238] py-1 text-sm font-semibold leading-6 hover:focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                <item.icon className="w-6 h-6 text-[#9FA0A4]" />
                {item.name}
              </button>
            </MenuItem>
          ))}
          <MenuItem
            onClick={signOut}
            className="flex gap-3 sm:px-2 justify-center  border border-[#770737] rounded-xl bg-white py-1.5 leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 shadow-sm hover:focus-visible:outline"
          >
            <button className="text-[#770737] text-[16px] text-sm font-semibold">
              Sign Out Account
            </button>
          </MenuItem>
        </div>
        <span className="text-[12px] text-[#082431] px-4">
          Other workspaces
        </span>
        <div className="bg-[#F6F6F6] border"></div>
        <div className="flex flex-col justify-center items-center gap-4 p-4 ">
          <MenuItem backgroundColor={"#F6F6F6"}>
            <button className="flex gap-4 sm:px-4 justify-center rounded-xl text-[#263238] py-1.5 text-sm font-semibold leading-6 hover:focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              <BiSolidUserCircle className="w-6 h-6 text-[#9FA0A4]" />
              Guest Workspace
            </button>
          </MenuItem>
          <MenuItem backgroundColor={"#F6F6F6"}>
            <button className="flex gap-2 px-3 justify-center rounded-xl bg-[#770737] py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:focus-visible:outline focus-visible-outline-2 focus-visible-outline-offset-2 focus-visible-outline-indigo-600">
              <IoMdAdd className="w-6 h-6" />
              Add new workspace
            </button>
          </MenuItem>
        </div>
      </MenuList>
    </Menu>
  );
};

export default ProfileMenu;
