export const BASE_URL = "/api";
// export const BASE_URL = "http://localhost:4000/api";
export const SIGN_IN = "auth/signup/";
export const VERIFY_EMAIL = "auth/verifyemail";
export const RESEND_OTP = "auth/resendotp";
export const LOGIN = "auth/login";
export const GET_FORGET_PASSWORD_OTP = "auth/getforgotpasswordotp";
export const VERIFy_FORGET_PASSWORD_OTP = "auth/verifyforgotpasswordotp";
export const RESET_PASSWORD = "auth/resetpassword";
export const CREATE_DATABASE = "database/create";
export const GET_DATABASES = "database/get";
export const UPDATE_DATABASE = "database/update";
export const DELETE_DATABASE = "database/delete";
export const CREATE_DATABASE_API_KEY = "database/createapikey";
export const CREATE_COLLECTION = "database/collection/create";
export const GET_COLLECTIONS = "database/collection/get";
export const UPDATE_COLLECTION = "database/collection/update";
export const DELETE_COLLECTION = "database/collection/delete";
export const GET_CHECKOUT = "stripe/create-checkout-session";
export const SAVE_SESSION = "stripe/save-session";
export const GET_CARD_DETAILS = "stripe/get-card-details";
export const GET_PAYMENT_DETAILS = "stripe/get-payment-details";
