import React, { useState, useEffect, useContext } from "react";
import { IoMdSettings } from "react-icons/io";
import { LiaCoinsSolid } from "react-icons/lia";
import MainLayout from "../../components/Layouts/MainLayout";
import { useParams } from "react-router-dom";
import CreateDatabaseDialog from "./CreateDatabaseDialog";
import { toast } from "react-hot-toast";
import { IoSearchSharp } from "react-icons/io5";
import { BsThreeDotsVertical } from "react-icons/bs";
import { GrEdit } from "react-icons/gr";
import { MdDelete } from "react-icons/md";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import { FaCopy } from "react-icons/fa";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  TabIndicator,
  Input,
  InputGroup,
  InputRightElement,
  Button,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Tooltip,
  Spinner,
} from "@chakra-ui/react";
import CreateApiKeyDialog from "./CreateApiKeyDialog";
import { getCollections } from "../../services/authServices";
import DeleteDialog from "../../components/Common/DeleteDialog";
import { DatabaseContext } from "../../context/DatabaseContext";
import MobileSidebar from "../../components/Common/SidebarMobile";

const Collection = () => {
  const { id } = useParams();
  const { database, setDatabase } = useContext(DatabaseContext);
  const [apiKey, setApiKey] = useState("");
  const [showApiKey, setShowApiKey] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedCollection, setSelectedCollection] = useState(null); // Store the selected collection
  const keyType = "collection";
  const [collectionData, setCollectionData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    fetchApiKey();
    // If the database is not in context, retrieve it from localStorage
    if (!database) {
      const savedDatabase = localStorage.getItem("selectedDatabase");
      if (savedDatabase) {
        setDatabase(JSON.parse(savedDatabase));
      }
    }
  }, [database, setDatabase]);

  const fetchData = async () => {
    setLoading(true);
    const token = localStorage.getItem("Token");
    const result = await getCollections(token, id);
    if (result.hasError) {
      return toast.error(result.message);
    } else {
      setCollectionData(result.collections);
      setFilteredData(result.collections); // Initialize with all data
    }
    setLoading(false);
  };

  const handleEditClick = (collection) => {
    setSelectedCollection(collection); // Set the selected collection
  };

  const handleSuccess = () => {
    fetchData();
  };

  const handleSearch = (e) => {
    const input = e.target.value;
    setSearchInput(input);
    if (input === "") {
      setFilteredData(collectionData);
    } else {
      const filtered = collectionData.filter((collection) =>
        collection.col_name.toLowerCase().includes(input.toLowerCase())
      );
      setFilteredData(filtered);
    }
  };

  useEffect(() => {
    fetchData();
  }, [database]);

  const handleCopy = () => {
    navigator.clipboard.writeText(apiKey);

    // toast.success("Api key copied");

    // toast.success("Api key copied.", {
    //   style: {
    //     border: "1px solid #770737",
    //     padding: "16px",
    //     color: "#EBF1FD",
    //     backgroundColor: "#770737",
    //   },
    //   iconTheme: {
    //     primary: "#3AB364",
    //     secondary: "#FFFAEE",
    //   },
    // });

    toast.success("Api key copied.", {
      style: {
        width: "200px",
        border: "1px solid #770737",
        padding: "16px",
        color: "#770737",
        // borderRadius: "60px",
      },
      iconTheme: {
        primary: "#770737",
        secondary: "#FFFAEE",
      },
    });
  };

  const fetchApiKey = () => {
    const storedApiKey = localStorage.getItem("api_key");
    setApiKey(storedApiKey || "");
  };

  const onDelete = () => {};

  const handleSuccessApikey = () => {
    fetchApiKey();
  };

  return (
    <MainLayout>
      <div className="flex sm:flex-row justify-between sm:px-[60px] px-4 py-10 sm:h-[60px] h-[120px] items-center">
        <div className="mt-4 flex flex-col">
          <MobileSidebar />
          <span className="select-none sm:text-[32px] text-[20px] text-[#1F384C] font-semibold">
            Project Overview
          </span>
          <div className="flex gap-4 items-center justify-start">
            <div className="sm:flex hidden min-w-[12px] min-h-[12px] rounded-full bg-[#770737]"></div>
            <span className="select-none sm:text-[24px] text-[#770737] font-bold">
              {database ? database.db_name : "Loading..."}
            </span>
          </div>
        </div>
        <div className="flex sm:flex-row flex-col sm:items-center sm:gap-4 gap-2 ">
          <button className="flex gap-2 sm:px-4 justify-center rounded-xl border border-[#636467] bg-[#F6F6F6] px-3 py-1.5 text-sm font-semibold leading-6 text-[#636467] shadow-sm hover:focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            <IoMdSettings className="w-6 h-6" /> Project Settings
          </button>
          <button className="flex gap-2 sm:px-4 justify-center rounded-xl bg-[#770737] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            <LiaCoinsSolid className="w-7 h-7 text-yellow-500" />
            Upgrade Free Credits
          </button>
        </div>
      </div>
      <div className="mt-12 w-full sm:px-16">
        <Tabs variant="enclosed" borderBottomColor={"#C8CBD9"}>
          <TabList
            gap={2}
            boxShadow={"-2px 2px 4px -1px rgba(0, 0, 0, 0.25)"}
            paddingBottom={"2px"}
          >
            <Tab
              backgroundColor={"#E9E9E9"}
              _selected={{ color: "#770737", fontWeight: "bold" }}
              sx={{
                fontSize: ["14px", "16px"], // 14px on mobile, 16px on larger screens
              }}
            >
              Database Repository
            </Tab>
            <Tab
              backgroundColor={"#E9E9E9"}
              _selected={{ color: "#770737", fontWeight: "bold" }}
              sx={{
                fontSize: ["14px", "16px"], // 14px on mobile, 16px on larger screens
              }}
            >
              Data Embedding
            </Tab>
            <Tab
              backgroundColor={"#E9E9E9"}
              _selected={{ color: "#770737", fontWeight: "bold" }}
              sx={{
                fontSize: ["14px", "16px"], // 14px on mobile, 16px on larger screens
              }}
            >
              Manage API Key
            </Tab>
            <Tab
              backgroundColor={"#E9E9E9"}
              _selected={{ color: "#770737", fontWeight: "bold" }}
              sx={{
                fontSize: ["14px", "16px"], // 14px on mobile, 16px on larger screens
              }}
            >
              Data Analytics
            </Tab>
          </TabList>
          <TabIndicator
            mt="-1.5px"
            height="2px"
            bg="#770737"
            borderRadius="1px"
          />

          <TabPanels>
            <TabPanel padding={0}>
              <div className="flex flex-col gap-6 sm:pr-6 px-4 pt-6  sm:mr-4 sm:h-[calc(100vh-258px)] h-[calc(100vh-350px)] overflow-auto scrollbar-color">
                {collectionData.length !== 0 ? (
                  <div className="flex sm:flex-row sm:gap-0 gap-4 flex-col items-center sm:justify-between">
                    <div className="sm:w-[80%] w-[350px]">
                      <InputGroup size="sm">
                        <Input
                          _focus={{ boxShadow: "none", border: "none" }}
                          backgroundColor={"#F6F6F6"}
                          placeholder="Search Collection"
                          value={searchInput}
                          onChange={handleSearch}
                          className="custom-button"
                        />
                        <InputRightElement width="4.5rem">
                          <Button
                            h="2rem"
                            marginLeft={"2px"}
                            backgroundColor={"#770737"}
                            size="lg"
                            _hover={{}}
                            roundedLeft={"none"}
                            className="custom-button"
                          >
                            <IoSearchSharp className="text-white w-5 h-5" />
                          </Button>
                        </InputRightElement>
                      </InputGroup>
                    </div>
                    <div>
                      <CreateDatabaseDialog
                        keyType={keyType}
                        id={id}
                        onSuccess={handleSuccess}
                      />
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                {loading ? (
                  <div className="flex justify-center items-center mt-[300px]">
                    <Spinner
                      thickness="6px"
                      speed="0.65s"
                      emptyColor="gray.200"
                      color="#770737"
                      size="xl"
                    />{" "}
                  </div>
                ) : collectionData.length === 0 ? (
                  <div className="sm:pt-[100px] pt-[40px] flex justify-center items-center flex-col">
                    <img
                      className="sm:h-[25vh] h-[20vh]"
                      src="../../../../collection.png"
                      alt="No databases"
                    />
                    <div className="flex flex-col items-center sm:mx-auto sm:w-full sm:max-w-lg sm:mt-4">
                      <h1 className="select-none text-center sm:text-[22px] text-[16px] text-[#770737] font-sans font-bold font-semibold">
                        No records found in this database
                      </h1>
                      <h6 className="select-none text-center sm:text-[16px] text-[12px] font-sans font-normal">
                        You can create a new collection of records OR import one
                      </h6>
                      <div className="sm:mt-6 mt-2">
                        <CreateDatabaseDialog
                          keyType={keyType}
                          id={id}
                          onSuccess={handleSuccess}
                        />
                      </div>
                    </div>
                    <div className="sm:mt-6 mt-2"></div>
                  </div>
                ) : (
                  filteredData.map((collection) => (
                    <div
                      key={collection._id}
                      className="flex flex-row justify-between sm:px-6 px-4 py-4 bg-[#F4F4F4] rounded-2xl min-h-[150px] cursor-pointer shadow shadow-[0px 4px 4px 0px rgba(0, 0, 0, 0.25)] hover:shadow-[0px 20px 20px 0px rgba(0, 0, 0, 0.25)] hover:bg-[#e8e8e8]"
                    >
                      <div>
                        <Text
                          className="select-none text-[#1F384C] sm:text-[28px] text-[22px]"
                          mb="2px"
                        >
                          {collection.col_name}
                        </Text>
                        <div className="flex flex-row sm:gap-4 gap-3 items-center">
                          <div className="min-w-[6px] min-h-[6px] rounded-full bg-[#273240]"></div>
                          <Text
                            className="select-none text-[#696974] text-[14px]"
                            mb="2px"
                          >
                            {collection.col_description}
                          </Text>
                          <div className="min-w-[6px] min-h-[6px] rounded-full bg-[#273240]"></div>
                          <Text
                            className="select-none text-[#696974] text-[14px]"
                            mb="2px"
                          >
                            {collection.metadata}
                          </Text>
                        </div>
                      </div>
                      <div className="sm:flex sm:flex-row">
                        <Menu>
                          <MenuButton
                            onClick={(event) => {
                              event.stopPropagation();
                              handleEditClick(collection);
                            }}
                            marginTop={"-356%"}
                          >
                            <BsThreeDotsVertical className="mt-2 w-6 h-6 text-[#636467] cursor-pointer" />
                          </MenuButton>
                          <MenuList
                            margin={"-102px"}
                            backgroundColor={"#E9E9E9"}
                            borderRadius={"20px"}
                          >
                            <MenuItem
                              onClick={(event) => {
                                event.stopPropagation();
                              }}
                              backgroundColor={"transparent"}
                            >
                              <CreateDatabaseDialog
                                keyType={keyType}
                                id={id}
                                onSuccess={handleSuccess}
                                selectedCollectionData={selectedCollection}
                              />
                            </MenuItem>
                            <MenuItem
                              onClick={(event) => {
                                event.stopPropagation();
                              }}
                              backgroundColor={"transparent"}
                            >
                              <DeleteDialog
                                keyType={"collection"}
                                dbId={id}
                                colId={collection._id}
                                onSuccess={handleSuccess}
                              />
                              {/* <button className="flex gap-2 sm:px-4 min-w-[220px] justify-start rounded-xl border border-[#636467] bg-[#F6F6F6] px-3 py-1.5 text-sm font-semibold leading-6 text-[#BE2B2B] shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                                        <MdDelete className="w-5 h-5 mt-0.5" /> Delete Project
                                      </button> */}
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </TabPanel>

            <TabPanel></TabPanel>

            <TabPanel>
              {loading ? (
                <div className="flex justify-center items-center mt-[300px]">
                  <Spinner
                    thickness="6px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="#770737"
                    size="xl"
                  />{" "}
                </div>
              ) : apiKey === "" ? (
                <div className="sm:width-full width-[100px] flex flex-col gap-6 px-12 pt-6 sm:ml-4 ml-2 sm:mr-4 mr-2 sm:h-[calc(100vh-292px)] h-[calc(100vh-400px)] overflow-auto scrollbar-color">
                  <div className="sm:pt-[100px] pt-[40px] flex justify-center items-center flex-col">
                    <img
                      className="sm:h-[20vh] h-[12vh]"
                      src="../../../../api.png"
                      alt="No databases"
                    />
                    <div className="sm:mx-auto sm:w-full sm:max-w-lg sm:mt-4">
                      <h1 className="select-none text-center sm:text-[22px] text-[16px] text-[#770737] font-sans font-bold font-semibold">
                        There are no API Keys for this Database{" "}
                      </h1>
                      <h6 className="select-none text-center sm:text-[16px] text-[12px] font-sans font-normal">
                        You can create a new API Key for your Database{" "}
                      </h6>
                      <div className="sm:mt-6 mt-2">
                        <CreateApiKeyDialog
                          id={id}
                          onSuccess={handleSuccessApikey}
                        />
                      </div>
                    </div>
                    <div className="sm:mt-6 mt-2"></div>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col gap-6 sm:pr-6 px-4 pt-6 sm:mr-4 sm:h-[calc(100vh-290px)] h-[calc(100vh-450px)] overflow-auto scrollbar-color">
                  <div className="flex flex-row justify-between sm:px-6 px-4 py-4 bg-[#F4F4F4] rounded-2xl min-h-[150px] cursor-pointer shadow shadow-[0px 4px 4px 0px rgba(0, 0, 0, 0.25)] hover:shadow-[0px 20px 20px 0px rgba(0, 0, 0, 0.25)] hover:bg-[#e8e8e8]">
                    <div>
                      <Text
                        className="select-none text-[#1F384C] sm:text-[28px] text-[22px]"
                        mb="2px"
                      >
                        {database ? database.db_name : "Loading..."}
                      </Text>
                      <div className="flex flex-row mt-2 sm:gap-4 gap-3 items-center">
                        <Text
                          className="select-none text-[#770737] sm:text-[18px] text-[16px]"
                          mb="2px"
                        >
                          Each database will have a unique API Key
                        </Text>
                      </div>
                      <div className="mt-3 flex sm:flex-row flex-col  sm:gap-4 gap-3 items-center">
                        <div className="sm:flex hidden min-w-[12px] min-h-[12px] rounded-full bg-[#770737]"></div>
                        <Text
                          className="text-[#696974] sm:text-[24px] text-[16px]"
                          mb="2px"
                        >
                          {showApiKey
                            ? apiKey
                            : "•••••••••••••••••••••••••••••••••••••••••••••"}
                        </Text>
                        <div className="flex flex-row gap-4 sm:ml-10">
                          <Tooltip
                            label={showApiKey ? "Hide API Key" : "Show API Key"}
                          >
                            <IconButton
                              icon={
                                showApiKey ? (
                                  <IoEyeOffOutline />
                                ) : (
                                  <IoEyeOutline />
                                )
                              }
                              onClick={() => setShowApiKey(!showApiKey)}
                              aria-label="Toggle API Key Visibility"
                              size="sm"
                            />
                          </Tooltip>
                          <Tooltip label="Copy API Key">
                            <IconButton
                              icon={<FaCopy />}
                              onClick={handleCopy}
                              aria-label="Copy API Key"
                              size="sm"
                            />
                          </Tooltip>
                          {/* <Tooltip label="Delete API Key">
                            <IconButton
                              icon={<MdDelete />}
                              onClick={onDelete}
                              aria-label="Delete API Key"
                              size="sm"
                              colorScheme="red"
                            />
                          </Tooltip> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </TabPanel>

            <TabPanel></TabPanel>
          </TabPanels>
        </Tabs>
      </div>
    </MainLayout>
  );
};

export default Collection;
