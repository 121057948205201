import React from "react";
import { CgNotes } from "react-icons/cg";
import { IoSettings } from "react-icons/io5";
import { FaRegCreditCard, FaUserTie, FaUser, FaBell } from "react-icons/fa";
import { AiFillProject } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const Sidebar = () => {
  const navigate = useNavigate();

  const Data = [
    {
      heading: "Project Workspace",
      body: [
        {
          icon: CgNotes,
          subHeading: "Project Dashboard",
          nav: "/dashboard",
        },
      ],
    },
    {
      heading: "Data and Deployment",
      body: [
        {
          icon: FaBell,
          subHeading: "Alerts and Notifications",
          nav: "/comingsoon?page=alerts",
        },
        {
          icon: AiFillProject,
          subHeading: "Activity Feed",
          nav: "/comingsoon?page=activity",
        },
      ],
    },
    {
      heading: "Account Privacy & Security",
      body: [
        {
          icon: FaUser,
          subHeading: "Manage Account",
          nav: "/comingsoon?page=manageaccount",
        },
        {
          icon: FaRegCreditCard,
          subHeading: "Payment & Billing",
          nav: "/payment",
        },
        {
          icon: FaUserTie,
          subHeading: "Contact Support",
          nav: "/comingsoon?page=contactsupport",
        },
        {
          icon: IoSettings,
          subHeading: "Account Settings",
          nav: "/comingsoon?page=settings",
        },
      ],
    },
  ];

  const goto = (nav) => {
    navigate(nav);
  };

  const isCurrentPage = (nav) => {
    const navUrl = new URL(nav, window.location.origin);
    const currentUrl = new URL(window.location.href);

    return (
      currentUrl.pathname === navUrl.pathname &&
      currentUrl.search === navUrl.search
    );
  };
  return (
    <div className="hidden sm:block mt-0.5 w-[320px] sm:h-[calc(100vh-90px)] h-[calc(100vh-240px)] bg-[#F4F4F4] py-6 sm:flex hidden flex-col justify-between">
      <div className="flex flex-col gap-8">
        {Data.map((item, index) => (
          <div key={index}>
            <div className="text-[16px] w-full text-[#8C8C94] flex justify-start px-6">
              {item.heading}
            </div>
            <div className="mt-4 sm:mx-auto w-full sm:max-w-sm px-6">
              <div className="border-t border-[#C8CBD9] mx-auto"></div>
            </div>

            {item.body.map((bodyItem, bodyIndex) => (
              <div
                key={bodyIndex}
                className="group flex flex-row cursor-pointer"
                onClick={() => goto(bodyItem.nav)}
              >
                <div
                  className={`w-[10px] h-[38px] mt-[16px] ${
                    isCurrentPage(bodyItem.nav)
                      ? "bg-[#770737] block"
                      : "hidden group-hover:bg-[#770737] group-focus-within:bg-[#770737] group-hover:block group-focus-within:block"
                  }`}
                ></div>
                <div
                  tabIndex="0"
                  className={`w-full flex mt-4 flex-row justify-start items-center text-[14px] text-[#273240] gap-4 py-2 px-8 ${
                    isCurrentPage(bodyItem.nav)
                      ? "bg-[#f7ebeb]"
                      : "group-hover:bg-[#f7ebeb] group-focus-within:bg-[#f7ebeb]"
                  }`}
                >
                  <bodyItem.icon className="w-5 h-5 text-[#ABABAB]" />
                  {bodyItem.subHeading}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className="flex justify-center items-center bg-[#770737] text-white ml-10 rounded-2xl w-[180px] h-[40px] text-[16px]">
        MulberryDB v0.0.1
      </div>
    </div>
  );
};

export default Sidebar;
