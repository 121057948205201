import React from "react";
import { useParams } from "react-router-dom";

const StripeReturn = () => {
  const { checkoutSessionId } = useParams();

  // Use the checkoutSessionId in your component logic
  console.log("Checkout Session ID:", checkoutSessionId);

  return (
    <div>
      <h1>Stripe Return</h1>
      <p>Checkout Session ID: {checkoutSessionId}</p>
    </div>
  );
};

export default StripeReturn;
