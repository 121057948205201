import React from "react";
import MainLayout from "../../components/Layouts/MainLayout";

export default function CommingSoon() {
  return (
    <>
      <MainLayout>
        <div className="w-full min-h-[calc(100vh-84px)] flex flex-col justify-center items-center gap-5">
          <p className="text-center sm:text-[42px] text-[32px] text-[#770737] font-sans font-bold font-bold">
            COMMING SOON
          </p>
          <img
            src="../../../../coming_soon.svg"
            className="sm:h-[50vh] h-[30vh]"
            alt="Coming Soon"
          />
        </div>
      </MainLayout>
    </>
  );
}
