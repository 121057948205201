import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import MainLayout from "../../components/Layouts/MainLayout";
import { IoMdSettings } from "react-icons/io";
import { LiaCoinsSolid } from "react-icons/lia";
import CreateDatabaseDialog from "./CreateDatabaseDialog";
import { BsThreeDotsVertical } from "react-icons/bs";
import { GrDocumentText } from "react-icons/gr";
import { RiShareForwardFill } from "react-icons/ri";
import { GrEdit } from "react-icons/gr";
import { BsGlobe2 } from "react-icons/bs";
import { FaLock } from "react-icons/fa6";

import {
  Input,
  InputGroup,
  InputRightElement,
  Button,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Spinner,
} from "@chakra-ui/react";
import { IoSearchSharp } from "react-icons/io5";
import { getDatabases } from "../../services/authServices";
import DeleteDialog from "../../components/Common/DeleteDialog";
import { DatabaseContext } from "../../context/DatabaseContext";
import MobileSidebar from "../../components/Common/SidebarMobile";

const Dashboard = () => {
  const navigate = useNavigate();
  const [databaseData, setDatabaseData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const { setDatabase } = useContext(DatabaseContext);
  const [loading, setLoading] = useState(true);

  const keyType = "database";

  const fetchData = async () => {
    setLoading(true);
    const token = localStorage.getItem("Token");
    const result = await getDatabases(token);
    setDatabaseData(result.data);
    setFilteredData(result.data); // Initialize with all data
    setLoading(false);
  };

  const navigatetoDatabase = (database) => {
    setDatabase(database);
    if (!database.api_key) {
      localStorage.setItem("api_key", "");
    } else {
      localStorage.setItem("api_key", database.api_key);
    }
    navigate(`/collection/${database._id}`);
  };

  const handleSuccess = () => {
    fetchData();
  };

  const handleSearch = (e) => {
    const input = e.target.value;
    setSearchInput(input);
    if (input === "") {
      setFilteredData(databaseData);
    } else {
      const filtered = databaseData.filter((database) =>
        database.db_name.toLowerCase().includes(input.toLowerCase())
      );
      setFilteredData(filtered);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <MainLayout>
      <div className="flex sm:flex-row justify-between sm:px-[60px] px-4 py-10 sm:h-[60px] h-[120px] items-center">
        <div>
          <MobileSidebar />
          <span className="text-[32px] text-[#1F384C] font-semibold">
            Dashboard
          </span>
        </div>
        <div className="flex sm:flex-row flex-col sm:items-center sm:gap-4 gap-2 ">
          <button className="flex gap-2 sm:px-4 justify-center rounded-xl border border-[#636467] bg-[#F6F6F6] px-3 py-1.5 text-sm font-semibold leading-6 text-[#636467] shadow-sm hover:focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            <IoMdSettings className="w-6 h-6" /> Workspace Settings
          </button>
          <button className="flex gap-2 sm:px-4 justify-center rounded-xl bg-[#770737] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            <LiaCoinsSolid className="w-7 h-7 text-yellow-500" />
            Upgrade Free Credits
          </button>
        </div>
      </div>
      <div className="mt-2 w-full sm:px-16 px-6">
        <div className="border-t border-gray-250 shadow shadow-[0px 2px 4px 0px rgba(0, 0, 0, 0.25] mx-auto"></div>
      </div>

      <div className="flex flex-col gap-6 sm:px-12 pt-6 sm:ml-4 ml-2 sm:mr-4 mr-2 sm:h-[calc(100vh-180px)] h-[calc(100vh-240px)] overflow-auto scrollbar-color">
        {databaseData.length !== 0 ? (
          <div className="flex sm:flex-row sm:gap-0 gap-4 flex-col items-center sm:justify-between">
            <div className="sm:w-[80%] w-[350px]">
              <InputGroup size="sm">
                <Input
                  _focus={{ boxShadow: "none", border: "none" }}
                  backgroundColor={"#F6F6F6"}
                  placeholder="Search Collection"
                  value={searchInput}
                  onChange={handleSearch}
                  className="custom-input"
                />
                <InputRightElement width="4.5rem">
                  <Button
                    h="2rem"
                    marginLeft={"2px"}
                    backgroundColor={"#770737"}
                    size="lg"
                    _hover={{}}
                    roundedLeft={"none"}
                    className="custom-button"
                  >
                    <IoSearchSharp className="text-white w-5 h-5" />
                  </Button>
                </InputRightElement>
              </InputGroup>
            </div>

            <style jsx>{`
              .custom-input-group:focus-within {
                background-color: #f6f6f6;
              }
              .custom-input:focus,
              .custom-button:focus {
                outline: none;
                box-shadow: none;
              }
            `}</style>
            <div>
              <CreateDatabaseDialog
                keyType={keyType}
                onSuccess={handleSuccess}
              />
            </div>
          </div>
        ) : (
          <></>
        )}

        {loading ? (
          <div className="flex justify-center items-center mt-[300px]">
            <Spinner
              thickness="6px"
              speed="0.65s"
              emptyColor="gray.200"
              color="#770737"
              size="xl"
            />{" "}
          </div>
        ) : databaseData.length === 0 ? (
          <div className="pt-[170px] flex justify-center items-center flex-col">
            <img
              className="sm:h-[20vh] h-[15vh]"
              src="../../../../dashboard.png"
              alt="No databases"
            />
            <div className="sm:mx-auto sm:w-full sm:max-w-lg sm:mt-4">
              <h1 className="text-center sm:text-[22px] text-[16px] text-[#770737] font-sans font-bold font-semibold">
                Currently, there are no databases in this workspace
              </h1>
              <h6 className="text-center sm:text-[16px] text-[12px] font-sans font-normal">
                You can create a new database project OR import one
              </h6>
            </div>
            <div className="sm:mt-6 mt-2">
              <CreateDatabaseDialog
                keyType={keyType}
                onSuccess={handleSuccess}
              />
            </div>
          </div>
        ) : (
          filteredData.map((database) => (
            <div
              onClick={() => navigatetoDatabase(database)}
              key={database._id}
              className="flex flex-row justify-between sm:ml-0 ml-3 sm:max-w-full max-w-[375px] sm:px-6 px-4 py-4 bg-[#F4F4F4] rounded-2xl min-h-[150px] cursor-pointer shadow shadow-[0px 4px 4px 0px rgba(0, 0, 0, 0.25)] hover:shadow-[0px 20px 20px 0px rgba(0, 0, 0, 0.25)] hover:bg-[#e8e8e8]"
            >
              <div>
                <Text
                  className="text-[#1F384C] sm:text-[28px] text-[22px]"
                  mb="2px"
                >
                  {database.db_name}
                </Text>
                <div className="flex flex-row sm:gap-4 gap-3 items-center">
                  <div className="min-w-[6px] min-h-[6px] rounded-full bg-[#273240]"></div>
                  <Text className="text-[#696974] text-[14px]" mb="2px">
                    {database.description}
                  </Text>
                  <div className="min-w-[6px] min-h-[6px] rounded-full bg-[#273240]"></div>
                  <Text className="text-[#696974] text-[14px]" mb="2px">
                    {database.tags}
                  </Text>
                  {database.database_domain === "Private" ? (
                    <FaLock />
                  ) : (
                    <BsGlobe2 />
                  )}
                </div>
              </div>
              <div className="sm:flex sm:flex-row">
                <Menu>
                  <MenuButton
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    marginTop={"-46%"}
                  >
                    <BsThreeDotsVertical className="mt-2 w-6 h-6 text-[#636467] cursor-pointer" />
                  </MenuButton>
                  <MenuList
                    margin={"-82px"}
                    backgroundColor={"#E9E9E9"}
                    borderRadius={"20px"}
                  >
                    <MenuItem
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                      backgroundColor={"transparent"}
                    >
                      <button className="flex gap-2 sm:px-4 min-w-[220px] justify-start rounded-xl border border-[#636467] bg-[#F6F6F6] px-3 py-1.5 text-sm font-semibold leading-6 text-[#636467] shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                        <GrDocumentText className="w-5 h-5 mt-0.5" />
                        Database Configuration
                      </button>
                    </MenuItem>
                    <MenuItem
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                      backgroundColor={"transparent"}
                    >
                      <button className="flex gap-2 sm:px-4 min-w-[220px] justify-start rounded-xl border border-[#636467] bg-[#F6F6F6] px-3 py-1.5 text-sm font-semibold leading-6 text-[#636467] shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                        <RiShareForwardFill className="w-5 h-5 mt-0.5" /> Share
                        Database Link
                      </button>
                    </MenuItem>
                    <MenuItem
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                      backgroundColor={"transparent"}
                    >
                      <button className="flex gap-2 sm:px-4 min-w-[220px] justify-start rounded-xl border border-[#636467] bg-[#F6F6F6] px-3 py-1.5 text-sm font-semibold leading-6 text-[#636467] shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                        <GrEdit className="w-5 h-5 mt-0.5" /> Edit Database
                      </button>
                    </MenuItem>
                    <MenuItem
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                      backgroundColor={"transparent"}
                    >
                      <DeleteDialog
                        keyType={"database"}
                        id={database._id}
                        onSuccess={handleSuccess}
                      />
                      {/* <button className="flex gap-2 sm:px-4 min-w-[220px] justify-start rounded-xl border border-[#636467] bg-[#F6F6F6] px-3 py-1.5 text-sm font-semibold leading-6 text-[#BE2B2B] shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                        <MdDelete className="w-5 h-5 mt-0.5" /> Delete Project
                      </button> */}
                    </MenuItem>
                  </MenuList>
                </Menu>

                <div className="sm:flex hidden">
                  <Button
                    backgroundColor={"#F6F6F6"}
                    color={"#636467"}
                    border={"1px"}
                    borderColor={"#636467"}
                    _hover={{}}
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  >
                    Connect Database
                  </Button>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </MainLayout>
  );
};

export default Dashboard;
