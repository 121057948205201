import React from "react";
import HeaderCommon from "../Common/Header";
import Sidebar from "../Common/Sidebar";

const MainLayout = ({ children }) => {
  return (
    <div className="min-h-full">
      <HeaderCommon />
      <div className="flex flex-row">
        <Sidebar />
        <div className="w-full">{children}</div>
      </div>
    </div>
  );
};

export default MainLayout;
