import { extendTheme } from "@chakra-ui/react";

const themeInfo = {
  colors: {
    radio: {
      50: "#770737",
      100: "#770737",
      200: "#770737",
      300: "#770737",
      400: "#770737",
      500: "#770737",
      600: "#770737",
      700: "#770737",
      800: "#770737",
      900: "#770737",
    },
  },
};

const mainTheme = extendTheme(themeInfo);
export default mainTheme;
