import React, { createContext, useState, useEffect } from "react";

// Create a context with default value of an empty object
export const DatabaseContext = createContext({});

// Create a provider component
export const DatabaseProvider = ({ children }) => {
  const [database, setDatabase] = useState(() => {
    // Initialize state from localStorage
    const savedDatabase = localStorage.getItem("selectedDatabase");
    return savedDatabase ? JSON.parse(savedDatabase) : null;
  });

  useEffect(() => {
    // Save database to localStorage whenever it changes
    if (database) {
      localStorage.setItem("selectedDatabase", JSON.stringify(database));
    }
  }, [database]);

  return (
    <DatabaseContext.Provider value={{ database, setDatabase }}>
      {children}
    </DatabaseContext.Provider>
  );
};
