import {
  SIGN_IN,
  VERIFY_EMAIL,
  RESEND_OTP,
  LOGIN,
  VERIFy_FORGET_PASSWORD_OTP,
  RESET_PASSWORD,
  GET_FORGET_PASSWORD_OTP,
  CREATE_DATABASE,
  GET_DATABASES,
  UPDATE_DATABASE,
  DELETE_DATABASE,
  CREATE_COLLECTION,
  GET_COLLECTIONS,
  UPDATE_COLLECTION,
  DELETE_COLLECTION,
  GET_CHECKOUT,
  SAVE_SESSION,
  GET_CARD_DETAILS,
  GET_PAYMENT_DETAILS,
  CREATE_DATABASE_API_KEY,
} from "../utils/apiUrls";
import { postData, fetchData, deleteData } from "./commonServices";

export const registerUser = (payload) => {
  return postData(SIGN_IN, payload);
};

export const verifyEmail = (payload) => {
  return postData(VERIFY_EMAIL, payload);
};

export const resendOtp = (payload) => {
  return postData(RESEND_OTP, payload);
};

export const loginUser = (payload) => {
  return postData(LOGIN, payload);
};

export const getForgotPasswordOtp = (payload) => {
  return postData(GET_FORGET_PASSWORD_OTP, payload);
};

export const verifyForgotPasswordOtp = (payload, token) => {
  return postData(VERIFy_FORGET_PASSWORD_OTP, payload, token);
};

export const resetPassword = (payload, token) => {
  return postData(RESET_PASSWORD, payload, token);
};

export const createDatabaseApiKey = (payload, token) => {
  console.log(payload);
  return postData(CREATE_DATABASE_API_KEY, payload, token);
};

export const getDatabases = (token) => {
  return fetchData(GET_DATABASES, token);
};

export const updateDatabase = (payload, token) => {
  return postData(UPDATE_DATABASE, payload, token);
};

export const deleteDatabase = (id, token) => {
  return deleteData(DELETE_DATABASE, id, token);
};

export const createDatabase = (payload, token) => {
  return postData(CREATE_DATABASE, payload, token);
};

export const createCollection = (payload, token) => {
  console.log(payload);
  return postData(CREATE_COLLECTION, payload, token);
};

export const getCollections = (token, id) => {
  return fetchData(GET_COLLECTIONS, token, id);
};

export const updateCollection = (payload, token) => {
  console.log(payload);
  return postData(UPDATE_COLLECTION, payload, token);
};

export const deleteCollection = (params, token) => {
  return deleteData(DELETE_COLLECTION, params, token);
};

export const GetCheckoutSession = (token) => {
  console.log(token);
  return postData(GET_CHECKOUT, {}, token);
};

export const saveSession = (payload, token) => {
  console.log(payload);
  return postData(SAVE_SESSION, payload, token);
};

export const getCardDetails = (token) => {
  return fetchData(GET_CARD_DETAILS, token);
};

export const getPaymentDetails = (token) => {
  return fetchData(GET_PAYMENT_DETAILS, token);
};
