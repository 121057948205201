import React, { useState } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  useDisclosure,
  Spinner,
} from "@chakra-ui/react";
import { MdDelete } from "react-icons/md";
import { deleteCollection, deleteDatabase } from "../../services/authServices";
import toast from "react-hot-toast";

const DeleteDialog = ({ keyType, id, dbId, colId, onSuccess }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const [isLoading, setIsLoading] = useState(false);

  const onDelete = async () => {
    setIsLoading(true);

    if (keyType === "database") {
      const res = await deleteDatabase(id, localStorage.getItem("Token"));
      console.log(res);
      if (res.hasError) {
        return toast.error(res.message);
      } else {
        onClose();
        toast.success("Database Deleted.");
        onSuccess();
      }
    } else {
      const res = await deleteCollection(
        { db_id: dbId, col_id: colId },
        localStorage.getItem("Token")
      );
      console.log(res);
      if (res.hasError) {
        return toast.error(res.message);
      } else {
        onClose();
        toast.success("Collection Deleted.");
        onSuccess();
      }
    }
  };

  return (
    <>
      <button
        onClick={onOpen}
        className="flex gap-2 sm:px-4 min-w-[220px] justify-start rounded-xl border border-[#636467] bg-[#F6F6F6] px-3 py-1.5 text-sm font-semibold leading-6 text-[#BE2B2B] shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        <MdDelete className="w-5 h-5 mt-0.5" /> Delete Project
      </button>

      <AlertDialog
        isCentered
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent borderRadius={"20px"}>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {keyType === "database" ? "Delete Database" : "Delete Collection"}
            </AlertDialogHeader>

            <AlertDialogBody>
              {keyType === "database"
                ? "Are you sure you want to delete this database? You can't undo this action afterwards."
                : "Are you sure you want to delete this collection? You can't undo this action afterwards."}
            </AlertDialogBody>

            <AlertDialogFooter className="gap-2">
              <Button ref={cancelRef} rounded={"20px"} onClick={onClose}>
                Cancel
              </Button>
              <Button
                backgroundColor={"#770737"}
                color={"white"}
                rounded={"20px"}
                onClick={onDelete}
                _hover={{}}
                isDisabled={isLoading}
                cursor={isLoading ? "not-allowed" : "pointer"}
              >
                {isLoading ? (
                  <div className="flex justify-center items-center">
                    <Spinner
                      thickness="4px"
                      speed="0.65s"
                      emptyColor="gray.200"
                      color="#770737"
                      size="sm"
                    />
                    <span className="ml-2">Deleting...</span>
                  </div>
                ) : (
                  "Delete"
                )}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default DeleteDialog;
