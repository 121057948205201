import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  "pk_test_51POezBAC5SVUqz2KV455B5Pm97p2yfcEpjog0cj3LwOjutkIqPJ01MdWhAtQAXNsuD8tK1dkY1MDbCGMKo2LPp9w00wVhbV0Ka"
);

const CheckoutForm = ({ options }) => {
  console.log(options);
  // const fetchClientSecret = useCallback(() => {
  //   // Create a Checkout Session
  //   return fetch("/create-checkout-session", {
  //     method: "POST",
  //   })
  //     .then((res) => res.json())
  //     .then((data) => data.clientSecret);
  // }, []);

  // const options = { fetchClientSecret };

  return (
    <div id="checkout">
      <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  );
};

export default CheckoutForm;
