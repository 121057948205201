import React, { useState, useEffect } from "react";
import MainLayout from "../../components/Layouts/MainLayout";
import { IoMdSettings } from "react-icons/io";
import { LiaCoinsSolid } from "react-icons/lia";
import {
  GetCheckoutSession,
  getCardDetails,
  getPaymentDetails,
  saveSession,
} from "../../services/authServices";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  TabIndicator,
  Button,
  Spinner,
} from "@chakra-ui/react";
import CheckoutForm from "./Stripe";
import MobileSidebar from "../../components/Common/SidebarMobile";

const data = [
  {
    amount_paid: "20",
    month: "1",
    status: "succeeded",
  },
  {
    amount_paid: "10",
    month: "2",
    status: "succeeded",
  },
  {
    amount_paid: "20",
    month: "3",
    status: "succeeded",
  },
  {
    amount_paid: "50",
    month: "4",
    status: "declined",
  },
  {
    amount_paid: "80",
    month: "5",
    status: "succeeded",
  },
  {
    amount_paid: "30",
    month: "6",
    status: "succeeded",
  },
  {
    amount_paid: "50",
    month: "7",
    status: "succeeded",
  },
  {
    amount_paid: "20",
    month: "8",
    status: "succeeded",
  },
  {
    amount_paid: "10",
    month: "9",
    status: "succeeded",
  },
  {
    amount_paid: "30",
    month: "10",
    status: "succeeded",
  },
  {
    amount_paid: "20",
    month: "11",
    status: "succeeded",
  },
  {
    amount_paid: "40",
    month: "12",
    status: "succeeded",
  },
];

const Payment = () => {
  const [options, setOptions] = useState();
  const [cardData, setCardData] = useState({});
  const [paymentData, setPaymentData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const session_id = params.get("session_id");
    if (session_id) {
      // Save the session_id in the user table
      saveSession({ session_id }, localStorage.getItem("Token")).then(() => {
        // Remove session_id from URL
        const newUrl =
          window.location.protocol +
          "//" +
          window.location.host +
          window.location.pathname;
        window.history.replaceState({ path: newUrl }, "", newUrl);

        // Reload the page
        window.location.reload();
      });
    }
    getUserCardData();
    getUserPaymentData();
  }, []);

  const getUserCardData = async () => {
    const token = localStorage.getItem("Token");
    const result = await getCardDetails(token);
    console.log(result.cardDetails);
    setCardData(result.cardDetails || {});
  };

  const getUserPaymentData = async () => {
    setLoading(true);
    const token = localStorage.getItem("Token");
    const result = await getPaymentDetails(token);
    console.log(result.paymentDetails);
    // setPaymentData(result.paymentDetails || {});
    setPaymentData(data || {});
    setLoading(false);
  };

  const createCheckoutSession = async () => {
    const token = localStorage.getItem("Token");
    const result = await GetCheckoutSession(token);
    setOptions(result);
  };

  const getMonthName = (monthNumber) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return months[monthNumber - 1] || "Invalid Month";
  };

  return (
    <MainLayout>
      <div className="mt-6 flex sm:flex-row justify-between sm:px-[60px] px-4 py-10 sm:h-[60px] h-[120px] items-center">
        <div>
          <MobileSidebar />
          <span className="text-[32px] text-[#1F384C] font-semibold">
            Payment and Billing
          </span>
        </div>
        <div className="flex sm:flex-row flex-col sm:items-center sm:gap-4 gap-2 ">
          <button className="flex gap-2 sm:px-4 justify-center rounded-xl border border-[#636467] bg-[#F6F6F6] px-3 py-1.5 text-sm font-semibold leading-6 text-[#636467] shadow-sm hover:focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            <IoMdSettings className="w-6 h-6" /> Workspace Settings
          </button>
          <button className="flex gap-2 sm:px-4 justify-center rounded-xl bg-[#770737] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            <LiaCoinsSolid className="w-7 h-7 text-yellow-500" />
            Upgrade Free Credits
          </button>
        </div>
      </div>

      <div className="mt-4 sm:w-full w-[380px] sm:px-16 px-6">
        <Tabs variant="enclosed" borderBottomColor={"#C8CBD9"}>
          <TabList
            gap={{ base: 4, sm: 1 }}
            boxShadow={"-2px 2px 4px -1px rgba(0, 0, 0, 0.25)"}
            paddingBottom={"2px"}
          >
            <Tab
              width={{ base: "30%", sm: "10%" }}
              backgroundColor={"#E9E9E9"}
              _selected={{ color: "#770737", fontWeight: "bold" }}
            >
              Payment
            </Tab>
            <Tab
              width={{ base: "30%", sm: "10%" }}
              backgroundColor={"#E9E9E9"}
              _selected={{ color: "#770737", fontWeight: "bold" }}
            >
              Billing
            </Tab>
          </TabList>
          <TabIndicator
            mt="-1.5px"
            height="2px"
            bg="#770737"
            borderRadius="1px"
          />

          <TabPanels>
            <TabPanel padding={0}>
              <div className="flex sm:flex-row flex-col sm:justify-evenly justify-between gap-6 px-12 pt-6 sm:ml-4 ml-2 sm:mr-4 mr-2 sm:h-[calc(100vh-260px)] h-[calc(100vh-300px)] overflow-auto scrollbar-color">
                {loading ? (
                  <div className="flex justify-center items-center">
                    <Spinner
                      thickness="6px"
                      speed="0.65s"
                      emptyColor="gray.200"
                      color="#770737"
                      size="xl"
                    />{" "}
                  </div>
                ) : (
                  <>
                    <div className="flex flex-col">
                      <div className="flex flex-row gap-4 justify-start items-center">
                        <span className="text-[22px] text-[#1F384C] font-semibold">
                          Email:
                        </span>
                        <span className="text-[16px] text-[#1F384C] font-semibold">
                          {cardData.last4
                            ? localStorage.getItem("UserEmail")
                            : ""}
                        </span>
                      </div>
                      <div className="flex flex-row gap-4 justify-start items-center">
                        <span className="text-[22px] text-[#1F384C] font-semibold">
                          Name:
                        </span>
                        <span className="text-[16px] text-[#1F384C] font-semibold">
                          {cardData.last4
                            ? localStorage.getItem("UserName")
                            : ""}
                        </span>
                      </div>
                      <div className="flex flex-row gap-4 justify-start items-center">
                        <span className="text-[22px] text-[#1F384C] font-semibold">
                          Card:
                        </span>
                        <span className="text-[16px] text-[#1F384C] font-semibold">
                          {cardData.last4 ? cardData.last4 : ""}
                        </span>
                      </div>
                      <div className="flex flex-row gap-4 justify-start items-center">
                        <span className="text-[22px] text-[#1F384C] font-semibold">
                          Expiry Month:
                        </span>
                        <span className="text-[16px] text-[#1F384C] font-semibold">
                          {cardData.exp_month ? cardData.exp_month : ""}
                        </span>
                      </div>
                      <div className="flex flex-row gap-4 justify-start items-center">
                        <span className="text-[22px] text-[#1F384C] font-semibold">
                          Expiry Year:
                        </span>
                        <span className="text-[16px] text-[#1F384C] font-semibold">
                          {cardData.exp_year ? cardData.exp_year : ""}
                        </span>
                      </div>
                    </div>

                    <div className="flex flex-col">
                      {!cardData.last4 && (
                        <Button
                          backgroundColor={"#770737"}
                          color={"#F6F6F6"}
                          _hover={{}}
                          onClick={createCheckoutSession}
                        >
                          Add Payment
                        </Button>
                      )}
                      <div className="mt-4 w-[400px]">
                        {options && <CheckoutForm options={options} />}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </TabPanel>

            <TabPanel padding={0}>
              <div className="flex flex-row flex-wrap justify-center sm:gap-6 gap-12 px-12 pt-6 sm:ml-4 ml-2 sm:mr-4 mr-2 sm:h-[calc(100vh-260px)] h-[calc(100vh-300px)] overflow-auto scrollbar-color">
                {loading ? (
                  <div className="flex justify-center items-center">
                    <Spinner
                      thickness="6px"
                      speed="0.65s"
                      emptyColor="gray.200"
                      color="#770737"
                      size="xl"
                    />
                  </div>
                ) : Array.isArray(paymentData) ? (
                  paymentData.length === 0 ? (
                    <div className="flex justify-center items-center flex-col">
                      <img
                        className="sm:h-[25vh] h-[20vh]"
                        src="../../../../collection.png"
                        alt="No databases"
                      />
                      <div className="flex flex-col items-center sm:mx-auto sm:w-full sm:max-w-lg sm:mt-4">
                        <h1 className="text-center sm:text-[22px] text-[16px] text-[#770737] font-sans font-bold font-semibold">
                          No records found
                        </h1>
                        <h6 className="text-center sm:text-[16px] text-[12px] font-sans font-normal">
                          You haven't made any payments yet
                        </h6>
                      </div>
                      <div className="sm:mt-6 mt-2"></div>
                    </div>
                  ) : (
                    paymentData.map((pdata, index) => (
                      <div
                        key={index}
                        className="w-[20%] h-[20%] rounded-xl bg-[#F4F4F4] px-4 py-2 flex flex-col justify-center items-center"
                      >
                        <span className="text-[30px] font-bold text-[#770737]">
                          ${pdata.amount_paid}
                        </span>
                        <span className="text-[18px] font-bold text-[#8C8C94]">
                          Month: {getMonthName(parseInt(pdata.month))}
                        </span>
                        <span className="text-[18px] font-bold text-[#8C8C94]">
                          Payment: {pdata.status}
                        </span>
                      </div>
                    ))
                  )
                ) : (
                  <div className="w-[20%] h-[20%] rounded-xl bg-[#F4F4F4] px-4 py-2 flex flex-col justify-center items-center">
                    <span className="text-[30px] font-bold text-[#770737]">
                      ${paymentData.amount_paid}
                    </span>
                    <span className="text-[18px] font-bold text-[#8C8C94]">
                      Month: {getMonthName(parseInt(paymentData.month))}
                    </span>
                    <span className="text-[18px] font-bold text-[#8C8C94]">
                      Payment: {paymentData.status}
                    </span>
                  </div>
                )}
              </div>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </div>
    </MainLayout>
  );
};

export default Payment;
