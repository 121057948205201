import { useState } from "react";
import AuthLayout from "../../components/Layouts/AuthLayout";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";

import {
  registerUser,
  resendOtp,
  verifyEmail,
} from "../../services/authServices";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const Register = () => {
  const [is_OTP, setIs_OTP] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formData, setFormData] = useState({
    user_name: "",
    email: "",
    mobile: "",
    password: "",
    rePassword: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isChecked === true) {
      const validate = validatePassword(formData.password);

      if (!validate)
        return toast.error(
          "Password must contain at least 1 uppercase, 1 special character, and 1 digit, with a minimum length of 8 characters."
        );

      if (formData.rePassword !== formData.password)
        return toast.error("passwords doesn't match");

      const res = await registerUser(formData);
      console.log(res);
      if (res.hasError) {
        return toast.error(res.message);
      } else {
        toast.success(res.message);
        setIs_OTP(true);
      }
    } else {
      return toast.error("Please agree to our privacy policy");
    }
  };

  const validatePassword = (value) => {
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (!regex.test(value)) {
      return false;
    } else {
      return true;
    }
  };

  const [otp, setOtp] = useState("");

  const OtpChange = (e) => {
    setOtp(e.target.value);
  };

  const verifyOTP = async (e) => {
    e.preventDefault();
    const otpValue = otp;
    console.log(otpValue);

    const values = { email: formData.email, verify_email_otp: otpValue };

    if (otpValue !== "" && otpValue.length === 6) {
      console.log("values", values);
      const res = await verifyEmail(values);
      console.log(res);
      if (res.hasError) {
        return toast.error(res.message);
      } else {
        navigate("/auth/login");
      }
    } else {
      toast.error("Please provide OTP.");
    }
  };

  const resendOTP = async () => {
    const res = await resendOtp({ email: formData.email });
    console.log(res);
    if (res.hasError) {
      return toast.error(res.message);
    } else {
      toast.success("OTP resend.");
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <AuthLayout uniqueKey="register">
      {!is_OTP && (
        <div>
          <form className="mt-8" onSubmit={handleSubmit} method="POST">
            {/* FullName Input */}
            <div className="mt-2 relative">
              <input
                id="user_name"
                name="user_name"
                type="text"
                autoComplete="name"
                required
                value={formData.user_name}
                onChange={handleChange}
                className="block w-full px-[16px] py-1.5 text-gray-900 bg-white border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300 sm:text-sm sm:leading-6 peer"
                placeholder=" "
              />
              <label
                htmlFor="user_name"
                className={`absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-500 transition-all duration-200 ease-in-out
          ${formData.user_name ? "hidden" : "text-sm top-1/2"}
        `}
              >
                Full Name
              </label>
            </div>

            {/* Email Input */}
            <div className="mt-2 relative">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                value={formData.email}
                onChange={handleChange}
                className="block w-full px-[16px] py-1.5 text-gray-900 bg-white border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300 sm:text-sm sm:leading-6 peer"
                placeholder=" "
              />
              <label
                htmlFor="email"
                className={`absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-500 transition-all duration-200 ease-in-out
                ${formData.email ? "hidden" : "text-sm top-1/2"}
                `}
              >
                Email address
              </label>
            </div>

            {/* Mobile Number Input */}
            <div className="mt-2 relative">
              <input
                id="mobile"
                name="mobile"
                type="tel"
                autoComplete="tel"
                required
                value={formData.mobile}
                onChange={handleChange}
                className="block w-full px-[16px] py-1.5 text-gray-900 bg-white border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300 sm:text-sm sm:leading-6 peer"
                placeholder=" "
              />
              <label
                htmlFor="mobile"
                className={`absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-500 transition-all duration-200 ease-in-out
                ${formData.mobile ? "hidden" : "text-sm top-1/2"}
                `}
              >
                Mobile Number
              </label>
            </div>

            {/* Password Input */}
            <div className="mt-2 relative">
              <input
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                autoComplete="current-password"
                required
                value={formData.password}
                onChange={handleChange}
                className="block w-full px-[16px] py-1.5 text-gray-900 bg-white border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300 sm:text-sm sm:leading-6 peer"
                placeholder=" "
              />
              <label
                htmlFor="password"
                className={`absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-500 transition-all duration-200 ease-in-out
        ${formData.password ? "hidden" : "text-sm top-1/2"}
      `}
              >
                Password
              </label>
              <button
                type="button"
                className="absolute inset-y-0 right-0 pr-3 flex items-center"
                onClick={toggleShowPassword}
              >
                {showPassword ? (
                  <IoEyeOffOutline className="h-6 w-6 text-gray-600" />
                ) : (
                  <IoEyeOutline className="h-6 w-6 text-gray-600" />
                )}
              </button>
            </div>

            {/* Confirm Password Input */}
            <div className="mt-2 relative">
              <input
                id="rePassword"
                name="rePassword"
                type={showConfirmPassword ? "text" : "password"}
                autoComplete="current-password"
                required
                value={formData.rePassword}
                onChange={handleChange}
                className="block w-full px-[16px] py-1.5 text-gray-900 bg-white border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300 sm:text-sm sm:leading-6 peer"
                placeholder=" "
              />
              <label
                htmlFor="rePassword"
                className={`absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-500 transition-all duration-200 ease-in-out
          ${formData.rePassword ? "hidden" : "text-sm top-1/2"}
        `}
              >
                Confirm Password
              </label>
              <button
                type="button"
                className="absolute inset-y-0 right-0 pr-3 flex items-center"
                onClick={toggleShowConfirmPassword}
              >
                {showConfirmPassword ? (
                  <IoEyeOffOutline className="h-6 w-6 text-gray-600" />
                ) : (
                  <IoEyeOutline className="h-6 w-6 text-gray-600" />
                )}
              </button>
            </div>

            <div className="flex flex-row mt-4">
              <div className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  id="custom-checkbox"
                  className="form-checkbox h-4 w-4 text-transparent border-gray-300 rounded focus:ring-transparent"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
              </div>
              <div className="flex items-center space-x-2 ml-2">
                <span
                  htmlFor="custom-checkbox"
                  className="text-black-900 font-small"
                >
                  I have reviewed and agree, with all the term & conditions of
                  the
                  <a className="text-blue-400 ml-2 cursor-pointer">
                    Privacy Policy
                  </a>
                </span>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex mt-4 w-full justify-center rounded-md bg-[#770737] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#62052c] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Sign Up
              </button>
            </div>
          </form>
        </div>
      )}
      {is_OTP && (
        <div>
          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-sm">
            <h2 className="text-center text-[22px] font-sans font-bold font-semibold">
              Verify OTP
            </h2>
            <h6 className="text-center text-[14px] font-sans font-normal">
              We have sent a code to {formData.email}.
            </h6>
          </div>
          <div className="mt-8">
            <form id="otp-form" onSubmit={verifyOTP}>
              <div className="mt-2 relative">
                <input
                  id="otp"
                  name="otp"
                  type="text"
                  required
                  value={otp}
                  onChange={OtpChange}
                  className="block w-full px-[16px] py-1.5 text-gray-900 bg-white border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300 sm:text-sm sm:leading-6 peer"
                  placeholder=" "
                />
                <label
                  htmlFor="otp"
                  className={`absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-500 transition-all duration-200 ease-in-out
            ${otp ? "hidden" : "text-sm top-1/2"}
          `}
                >
                  OTP
                </label>
              </div>
              <div className="max-w-[160px] mx-auto mt-8">
                <button
                  type="submit"
                  className="flex mt-4 w-full justify-center rounded-md bg-[#770737] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#62052c] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Verify
                </button>
              </div>
            </form>
            <div className="max-w-[160px] mx-auto mt-4">
              <button
                onClick={resendOTP}
                className="flex mt-4 w-full justify-center rounded-md bg-[#F3E9E9] border-black border-2 text-[#770737] px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Resend
              </button>
            </div>
          </div>
        </div>
      )}
    </AuthLayout>
  );
};

export default Register;
