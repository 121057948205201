import React, { useState, useEffect } from "react";
import { IoMdAdd } from "react-icons/io";
import { toast } from "react-hot-toast";
import { GrEdit } from "react-icons/gr";

import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  Text,
  Textarea,
  FormControl,
  FormErrorMessage,
  Select,
  Stack,
  Radio,
  RadioGroup,
  Spinner,
} from "@chakra-ui/react";

import {
  createCollection,
  createDatabase,
  updateCollection,
} from "../../services/authServices";

const CreateDatabaseDialog = ({
  keyType,
  id,
  onSuccess,
  selectedCollectionData,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  console.log(selectedCollectionData);

  const [formData, setFormData] = useState({
    db_name: "",
    database_domain: "",
    description: "",
    tags: "",
  });

  const Close = () => {
    resetForm();
    onClose();
  };

  useEffect(() => {
    if (selectedCollectionData) {
      // Pre-fill form with selected collection data
      setFormData({
        db_name: selectedCollectionData.col_name || "",
        description: selectedCollectionData.col_description || "",
        tags: selectedCollectionData.metadata || "",
      });
    }
  }, [selectedCollectionData]);

  const [errors, setErrors] = useState({
    db_name: false,
    database_domain: false,
    description: false,
    tags: false,
  });

  // const handleChange = (event) => {
  //   console.log(event, "event");

  //   const { name, value } = event.target;
  //   setFormData({
  //     ...formData,
  //     [name]: value,
  //   });
  //   setErrors({
  //     ...errors,
  //     [name]: value === "",
  //   });
  // };

  const handleChange = (eventOrValue) => {
    if (typeof eventOrValue === "string") {
      setFormData({
        ...formData,
        tags: eventOrValue,
      });
      setErrors({
        ...errors,
        tags: eventOrValue === "",
      });
    } else {
      const { name, value } = eventOrValue.target;
      setFormData({
        ...formData,
        [name]: value,
      });
      setErrors({
        ...errors,
        [name]: value === "",
      });
    }
  };

  const resetForm = () => {
    setFormData({
      db_name: "",
      database_domain: "",
      description: "",
      tags: "",
    });
    setErrors({
      db_name: false,
      database_domain: false,
      description: false,
      tags: false,
    });
  };

  const CreateDatabase = async () => {
    if (keyType !== "database") {
      formData.database_domain = "none";
    }
    console.log(formData);
    const newErrors = {
      db_name: formData.db_name === "",
      database_domain: formData.database_domain === "",
      description: formData.description === "",
      tags: formData.tags === "",
    };

    if (Object.values(newErrors).some((error) => error)) {
      setErrors(newErrors);
    } else {
      setIsLoading(true);
      try {
        if (keyType === "database") {
          console.log(formData);
          const res = await createDatabase(
            formData,
            localStorage.getItem("Token")
          );
          console.log(res);
          if (res.hasError) {
            toast.error(res.message);
          } else {
            resetForm();
            onClose();
            toast.success("Database Created Successfully.");
            onSuccess();
          }
        } else if (selectedCollectionData) {
          console.log(selectedCollectionData);
          const res = await updateCollection(
            {
              db_id: id,
              col_id: selectedCollectionData._id,
              col_name: formData.db_name,
              col_description: formData.description,
              metadata: formData.tags,
              dynamodb_collection_id:
                selectedCollectionData.dynamodb_collection_id,
            },
            localStorage.getItem("Token")
          );
          console.log(res);
          if (res.hasError) {
            toast.error(res.message);
          } else {
            resetForm();
            onClose();
            toast.success("Collection updated Successfully.");
            onSuccess();
          }
        } else {
          console.log(formData);
          const res = await createCollection(
            {
              db_id: id,
              col_name: formData.db_name,
              col_description: formData.description,
              metadata: formData.tags,
            },
            localStorage.getItem("Token")
          );
          console.log(res);
          if (res.hasError) {
            toast.error(res.message);
          } else {
            resetForm();
            onClose();
            toast.success("Collection Created Successfully.");
            onSuccess();
          }
        }
      } catch (error) {
        console.error("Error creating database/collection:", error);
        toast.error("An error occurred. Please try again.");
      } finally {
        setIsLoading(false);
      }
    }
  };
  const onDomainChange = (event) => {
    console.log(event.target);

    const { value } = event.target;
    console.log(value);

    setFormData((formData) => ({
      ...formData,
      database_domain: value,
    }));
  };

  return (
    <>
      {selectedCollectionData ? (
        <button
          onClick={onOpen}
          className="flex gap-2 sm:px-4 min-w-[220px] justify-start rounded-xl border border-[#636467] bg-[#F6F6F6] px-3 py-1.5 text-sm font-semibold leading-6 text-[#636467] shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          <GrEdit className="w-5 h-5 mt-0.5" /> Edit Collection
        </button>
      ) : (
        <button
          onClick={onOpen}
          className="flex gap-2 sm:px-4 justify-center rounded-xl bg-[#770737] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          <IoMdAdd className="w-6 h-6" />
          {keyType === "database"
            ? "Create New Database"
            : "Create New Collection"}
        </button>
      )}

      <Modal
        isCentered
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={Close}
      >
        <ModalOverlay />

        <ModalContent
          borderRadius={"35px"}
          padding={"10px"}
          maxWidth={"1024px"}
          maxHeight={"690px"}
          backgroundColor={"#F6F6F6"}
        >
          <ModalHeader>
            <span className="sm:text-[32px] text-[26px] text-[#1F384C]">
              {selectedCollectionData
                ? "Edit Collection"
                : keyType === "database"
                ? "Create a new Database"
                : "Create a new Collection"}
            </span>
            <div className="flex flex-row gap-2">
              <span className="text-[13px] text-[#1F384C]">
                {keyType === "database"
                  ? "My workspace -> Medical Record Management System"
                  : "Employee Record Management System -> "}
              </span>
              {/* <IoIosLock className="text-[#F7A343]" /> */}
            </div>
          </ModalHeader>

          <ModalCloseButton marginTop={"20px"} marginRight={"20px"} />

          <ModalBody pb={3} className="flex flex-col gap-3">
            {keyType === "database" ? (
              // database name and domain fields
              <div className="flex flex-row gap-24">
                <div className="flex flex-col gap-2">
                  <Text className="text-[#3C4E71]-500 text-[20px]" mb="2px">
                    Name
                  </Text>
                  <FormControl isInvalid={errors.db_name}>
                    <Input
                      name="db_name"
                      backgroundColor={"white"}
                      className="bg-white"
                      value={formData.db_name}
                      onChange={handleChange}
                      placeholder="Medical Record Management System"
                      noOfLines={1}
                      size="sm"
                      _focus={{ boxShadow: "none", borderColor: "darkgrey" }}
                    />
                    {!errors.db_name ? (
                      <Text className="text-black ml-4 text-[14px]">
                        {keyType === "database"
                          ? "Project names must be unique identifiers within the workspace."
                          : "Collection names must be unique identifiers within the project."}
                      </Text>
                    ) : (
                      <FormErrorMessage className="ml-4 text-[14px]">
                        {keyType === "database"
                          ? "Database Name is required."
                          : "Collection Name is required."}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </div>

                <div className="flex flex-col gap-2">
                  <Text className="text-[#3C4E71]-500 text-[20px]" mb="2px">
                    Domain
                  </Text>
                  <FormControl isInvalid={errors.database_domain}>
                    <Stack>
                      <Select
                        backgroundColor={"white"}
                        variant="outline"
                        value={formData.database_domain}
                        onChange={onDomainChange}
                      >
                        <option value=""></option>
                        <option value="Public">Public</option>
                        <option value="Private">Private</option>
                      </Select>
                    </Stack>
                    {!errors.db_name ? (
                      <Text className="text-black ml-4 text-[14px]">
                        Domain identifies your project accessibility
                      </Text>
                    ) : (
                      <FormErrorMessage className="ml-4 text-[14px]">
                        Domain is required
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </div>
              </div>
            ) : (
              // collection name field
              <div className="flex flex-col gap-2">
                <Text className="text-[#3C4E71]-500 text-[20px]" mb="2px">
                  Name
                </Text>
                <FormControl isInvalid={errors.db_name}>
                  <Input
                    name="db_name"
                    backgroundColor={"white"}
                    className="bg-white"
                    value={formData.db_name}
                    onChange={handleChange}
                    placeholder="Medical Record Management System"
                    noOfLines={1}
                    size="sm"
                    _focus={{ boxShadow: "none", borderColor: "darkgrey" }}
                  />
                  {!errors.db_name ? (
                    <Text className="text-black ml-4 text-[14px]">
                      {keyType === "database"
                        ? "Project names must be unique identifiers within the workspace."
                        : "Collection names must be unique identifiers within the project."}
                    </Text>
                  ) : (
                    <FormErrorMessage className="ml-4 text-[14px]">
                      Name is required.
                    </FormErrorMessage>
                  )}
                </FormControl>
              </div>
            )}

            {/*Description field*/}
            <div className="flex flex-col gap-2">
              <Text className="text-[#3C4E71]-500 text-[20px]" mb="2px">
                Description
              </Text>
              <FormControl isInvalid={errors.description}>
                <Textarea
                  name="description"
                  resize={"none"}
                  minHeight={"120px"}
                  backgroundColor={"white"}
                  value={formData.description}
                  onChange={handleChange}
                  placeholder="A complete electronic medical record of each patient is maintained which helps doctors provide the best possible treatment. A complete inventory records of medicines and dispensation details of each medicine is maintained in the system to avoid stock-outs and pilferage"
                  size="sm"
                  _focus={{ boxShadow: "none", borderColor: "darkgrey" }}
                />
                {!errors.description ? (
                  <Text className="text-black ml-4 text-[14px]">
                    {keyType === "database"
                      ? "Describe your database."
                      : "Describe your collection."}
                  </Text>
                ) : (
                  <FormErrorMessage className="ml-4 text-[14px]">
                    Description is required.
                  </FormErrorMessage>
                )}
              </FormControl>
            </div>

            {/*Tags field*/}
            <div className="flex flex-col gap-2">
              <Text className="text-[#3C4E71]-500 text-[20px]" mb="2px">
                Tags
              </Text>
              <FormControl isInvalid={errors.tags}>
                {keyType === "database" ? (
                  <Input
                    name="tags"
                    backgroundColor={"white"}
                    value={formData.tags}
                    onChange={handleChange}
                    placeholder="“Medical”, “Patient”, “Hospital”, etc"
                    noOfLines={1}
                    size="sm"
                    _focus={{ boxShadow: "none", borderColor: "darkgrey" }}
                  />
                ) : (
                  <RadioGroup
                    name="tags"
                    color={"grey"}
                    paddingBottom={"10px"}
                    // paddingLeft={"16px"}
                    onChange={handleChange}
                    value={formData.tags}
                  >
                    <Stack
                      spacing={5}
                      direction={{ base: "column", md: "row" }}
                    >
                      <Radio
                        borderColor={"darkgrey"}
                        colorScheme="radio"
                        value="text2vec"
                      >
                        text2vec
                      </Radio>
                      <Radio
                        borderColor={"darkgrey"}
                        colorScheme="radio"
                        value="sentence_transformers"
                      >
                        sentence_transformers
                      </Radio>
                      <Radio
                        borderColor={"darkgrey"}
                        colorScheme="radio"
                        value="open_clip"
                      >
                        open_clip
                      </Radio>
                    </Stack>
                  </RadioGroup>
                )}

                {!errors.tags ? (
                  <Text className="text-black ml-4 text-[14px]">
                    {keyType === "database"
                      ? "Tags can help you to manage and organize resources. Use tags to assign metadata to your database."
                      : "Use tags to assign metadata to your collection"}
                  </Text>
                ) : (
                  <FormErrorMessage className="ml-4 text-[14px]">
                    {keyType === "database"
                      ? "Tags are required"
                      : "Select a tag"}{" "}
                  </FormErrorMessage>
                )}
              </FormControl>
            </div>
          </ModalBody>

          <ModalFooter className="flex sm:flex-col justify-center items-center gap-2">
            <Button
              backgroundColor={"#770737"}
              color={"white"}
              rounded={"20px"}
              onClick={CreateDatabase}
              _hover={{}}
              isDisabled={isLoading}
              cursor={isLoading ? "not-allowed" : "pointer"}
            >
              {isLoading ? (
                <div className="flex justify-center items-center">
                  <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="#770737"
                    size="sm"
                  />
                  <span className="ml-2">
                    {selectedCollectionData ? "Updating..." : "Creating..."}
                  </span>{" "}
                </div>
              ) : selectedCollectionData ? (
                "Update"
              ) : (
                "Create"
              )}
            </Button>
            <Button
              backgroundColor={"white"}
              color={"#770737"}
              rounded={"20px"}
              border={"1px solid"}
              onClick={Close}
              _hover={{}}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreateDatabaseDialog;
