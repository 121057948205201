import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

const PrivateLayout = ({ children }) => {
  const navigate = useNavigate();

  const toastDisplayed = useRef(false);

  useEffect(() => {
    const checkTokenExpiry = () => {
      const tokenExpiry = localStorage.getItem("TokenExpiry");
      const currentTime = Date.now();

      if (currentTime > tokenExpiry) {
        if (!toastDisplayed.current) {
          localStorage.removeItem("Token");
          localStorage.removeItem("TokenExpiry");
          navigate("/");
          toast("Session Expired! Please login again.", {
            icon: "⚠️",
          });
          toastDisplayed.current = true;
        }
      }
    };

    checkTokenExpiry();
  }, [navigate]);

  return <>{children}</>;
};

export default PrivateLayout;
