import { useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import { Link } from "react-router-dom";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { FaBell } from "react-icons/fa";
import { IoMdSettings } from "react-icons/io";
import ProfileMenu from "../../pages/Dashboard/ProfileMenu";
import { NavLink, useLocation } from "react-router-dom";
import HeaderMenu from "./HeaderMenu";

const HeaderCommon = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  // const UserName = localStorage.getItem("UserName");

  useEffect(() => {
    const checkIsLoggedIn = () => {
      const token = localStorage.getItem("Token");
      if (token && token !== "") {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    };
    checkIsLoggedIn();
  }, []);

  const location = useLocation();

  const signOut = () => {
    console.log("before", localStorage.getItem("Token"));
    localStorage.removeItem("Token");
    localStorage.removeItem("TokenExpiry");
    console.log("after", localStorage.getItem("Token"));

    if (location.pathname === "/") {
      console.log(location.pathname, "logout");
      window.location.reload();
    } else {
      navigate("/");
    }
  };

  return (
    <header className="bg-white shadow shadow-[0px 2px 4px 0px #00000040] sm:h-[60px]">
      <nav
        className="mx-auto flex max-w-8xl items-center justify-between p-1 lg:px-[60px]"
        aria-label="Global"
      >
        <div className="flex lg:flex-1 items-center">
          <Link to="/" className="flex items-center gap-2">
            <img
              className="h-12 w-auto"
              src="../../../../mulberry-logo.svg"
              alt=""
            />
            <h2 className="text-[#770737] font-bold sm:text-2xl text-xl">
              MulberryDB
            </h2>
          </Link>
        </div>

        <div className="flex lg:hidden gap-4">
          {!isLoggedIn ? (
            <div></div>
          ) : (
            <div>
              <ProfileMenu className="backdrop-blur-xl" />
            </div>
          )}

          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="sm:flex hidden">
          <HeaderMenu />
        </div>
        {!isLoggedIn ? (
          <div className="hidden lg:flex lg:flex-1 lg:justify-end gap-3">
            <Link to="auth/login">
              <button className="flex justify-center rounded-full bg-[#770737] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                Log In
              </button>
            </Link>
            <Link to="auth/register">
              <button className="flex justify-center rounded-full bg-[#770737] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                Sign Up
              </button>
            </Link>
          </div>
        ) : (
          <div className="hidden lg:flex lg:flex-1 lg:justify-end items-center gap-3">
            <ProfileMenu className="backdrop-blur-xl" />
            <FaBell className="w-5 h-5 text-[#770737] cursor-pointer" />
            <IoMdSettings className="w-6 h-6 text-[#770737] cursor-pointer" />
          </div>
        )}
      </nav>
      <Dialog
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="#" className="-m-1.5 p-1.5">
              <img className="h-8 w-auto" src="../../../../Logo.png" alt="" />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div>
                <HeaderMenu />
              </div>
              {!isLoggedIn ? (
                <div className="py-6">
                  <Link to="auth/login">
                    <button className="flex justify-center rounded-full bg-[#770737] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                      Log In
                    </button>
                  </Link>
                  <Link to="auth/register">
                    <button className="flex mt-3 justify-center rounded-full bg-[#770737] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                      Sign Up
                    </button>
                  </Link>
                </div>
              ) : (
                <div className="ml-4 py-4 flex flex-row gap-4 justrify-center items-center">
                  <FaBell className="w-5 h-5 text-[#770737]" />
                  <IoMdSettings
                    onClick={signOut}
                    className="w-6 h-6 text-[#770737] cursor-pointer"
                  />
                </div>
              )}
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
};
export default HeaderCommon;
