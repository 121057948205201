import React, { useState } from "react";
import { useDisclosure } from "@chakra-ui/react";
import { IoMdAdd, IoIosLock } from "react-icons/io";
import { toast } from "react-hot-toast";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  Text,
  FormControl,
  FormErrorMessage,
  Spinner,
} from "@chakra-ui/react";
import { createDatabaseApiKey } from "../../services/authServices";

const CreateApiKeyDialog = ({ id, onSuccess }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
  });

  const [errors, setErrors] = useState({
    name: false,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: value === "",
    });
  };

  const resetForm = () => {
    setFormData({
      name: "",
    });
    setErrors({
      name: false,
    });
  };

  const Close = () => {
    resetForm();
    onClose();
  };

  const CreateDatabase = async () => {
    const database_id = id;
    const res = await createDatabaseApiKey(
      { database_id },
      localStorage.getItem("Token")
    );
    console.log(res);
    if (res.hasError) {
      return toast.error(res.message);
    } else {
      setIsLoading(true);
      localStorage.setItem("api_key", res.gen_api_key);
      toast.success("Api key generated Successfully.");
      Close();
      onSuccess();
    }
    setIsLoading(false);

    // const newErrors = {
    //   name: formData.name === "",
    // };

    // if (Object.values(newErrors).some((error) => error)) {
    //   setErrors(newErrors);
    // } else {
    //   // Your database creation logic here
    //   // Example: await createDatabase(formData);
    //   toast.success("API Key created successfully!");
    //   Close();
    // }
  };

  return (
    <>
      <button
        onClick={onOpen}
        className="flex gap-2 sm:px-4 sm:ml-40 ml-12 justify-center rounded-xl bg-[#770737] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        <IoMdAdd className="w-6 h-6" />
        Create a New API Key
      </button>

      <Modal
        isCentered
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={Close}
      >
        <ModalOverlay />
        <ModalContent
          borderRadius={"35px"}
          padding={"10px"}
          maxWidth={"724px"}
          maxHeight={"650px"}
          backgroundColor={"#F6F6F6"}
        >
          <ModalHeader>
            <span className="sm:text-[32px] text-[26px] text-[#1F384C]">
              Create an API Key.
            </span>
            <div className="flex flex-row gap-2">
              <span className="text-[13px] text-[#1F384C]">
                Employee Record Management System -{">"} Manage API Keys
              </span>
              <IoIosLock className="text-[#F7A343]" />
            </div>
          </ModalHeader>
          <ModalCloseButton marginTop={"20px"} marginRight={"20px"} />
          {/* <ModalBody pb={6} className="flex flex-col gap-3">
            <div className="flex flex-col gap-2">
              <Text className="text-[#3C4E71]-500 text-[20px]" mb="2px">
                Name
              </Text>
              <FormControl isInvalid={errors.name}>
                <Input
                  name="name"
                  backgroundColor={"white"}
                  className="bg-white"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Medical Record Management System"
                  noOfLines={1}
                  size="sm"
                  _focus={{ boxShadow: "none", borderColor: "darkgrey" }}
                />
                {!errors.name ? (
                  <Text className="text-black ml-4 text-[14px]">
                    API Key names must be unique identifiers within the
                    Database.
                  </Text>
                ) : (
                  <FormErrorMessage className="ml-4 text-[14px]">
                    name is required
                  </FormErrorMessage>
                )}
              </FormControl>
            </div>
          </ModalBody> */}

          <ModalFooter className="flex sm:flex-row justify-center items-center gap-2">
            <Button
              backgroundColor={"#770737"}
              color={"white"}
              rounded={"20px"}
              onClick={CreateDatabase}
              _hover={{}}
              isDisabled={isLoading}
              cursor={isLoading ? "not-allowed" : "pointer"}
            >
              {isLoading ? (
                <div className="flex justify-center items-center">
                  <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="#770737"
                    size="sm"
                  />
                  <span className="ml-2">Creating...</span>
                </div>
              ) : (
                "Create"
              )}
            </Button>
            <Button
              backgroundColor={"white"}
              color={"#770737"}
              rounded={"20px"}
              border={"1px solid"}
              onClick={Close}
              _hover={{}}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreateApiKeyDialog;
