import React from "react";
import { NavLink, useLocation } from "react-router-dom";

const HeaderMenu = () => {
  const location = useLocation();
  const activeLink =
    location.pathname === "/" ? "Home" : location.pathname.replace("/", "");

  return (
    <div className="flex sm:flex-row flex-col sm:space-y-0 space-y-2 sm:gap-x-6 sm:py-0 py-6">
      <NavLink
        to="/"
        className={`${
          activeLink === "Home" ? "bg-[#770737] text-white" : "text-black"
        } hover:bg-[#770737] hover:text-white rounded-md px-3 py-2 sm:text-sm text-lg font-medium`}
      >
        Home
      </NavLink>
      <NavLink
        // to="/features"
        className={`${
          activeLink === "features" ? "bg-[#770737] text-white" : "text-black"
        } hover:bg-[#770737] hover:text-white rounded-md px-3 py-2 sm:text-sm text-lg font-medium`}
      >
        Features
      </NavLink>
      <NavLink
        // to="/documentation"
        className={`${
          activeLink === "documentation"
            ? "bg-[#770737] text-white"
            : "text-black"
        } hover:bg-[#770737] hover:text-white rounded-md px-3 py-2 sm:text-sm text-lg font-medium`}
      >
        Documentation
      </NavLink>
      <NavLink
        // to="/pricing"
        className={`${
          activeLink === "pricing" ? "bg-[#770737] text-white" : "text-black"
        } hover:bg-[#770737] hover:text-white rounded-md px-3 py-2 sm:text-sm text-lg font-medium`}
      >
        Pricing
      </NavLink>
      <NavLink
        // to="/contact"
        className={`${
          activeLink === "contact" ? "bg-[#770737] text-white" : "text-black"
        } hover:bg-[#770737] hover:text-white rounded-md px-3 py-2 sm:text-sm text-lg font-medium`}
      >
        Contact
      </NavLink>
    </div>
  );
};

export default HeaderMenu;
