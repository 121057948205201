import { BASE_URL } from "../utils/apiUrls";

export const postData = (url, payload, token) => {
  const headers = {
    "Content-Type": "application/json",
  };

  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  return fetch(`${BASE_URL}/${url}`, {
    method: "POST",
    headers,
    body: JSON.stringify(payload),
  }).then((res) => res.json());
};

export const fetchData = (url, token, id) => {
  const headers = {
    "Content-Type": "application/json",
  };

  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  const fullUrl = id ? `${BASE_URL}/${url}?id=${id}` : `${BASE_URL}/${url}`;

  return fetch(fullUrl, {
    method: "GEt",
    headers,
  }).then((res) => {
    return res.json();
  });
};

export const deleteData = (url, params, token) => {
  const headers = {
    "Content-Type": "application/json",
  };

  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  let fullUrl = `${BASE_URL}/${url}`;

  // Check if params is an object (multiple parameters) or a string (single ID)
  if (typeof params === "object") {
    const queryString = new URLSearchParams(params).toString();
    fullUrl += `?${queryString}`;
  } else if (typeof params === "string") {
    fullUrl += `?Id=${params}`;
  }

  return fetch(fullUrl, {
    method: "DELETE",
    headers,
  }).then((res) => res.json());
};
