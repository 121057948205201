import React, { useState, useEffect } from "react";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import {
  resendOtp,
  loginUser,
  getForgotPasswordOtp,
  verifyForgotPasswordOtp,
  resetPassword,
} from "../../services/authServices";
import { toast } from "react-hot-toast";
import AuthLayout from "../../components/Layouts/AuthLayout";
import { useNavigate } from "react-router-dom";

const SignIn = () => {
  const [token, setToken] = useState({});
  const [is_login, setIs_login] = useState(true);
  const [is_GetOTP, setIs_GetOTP] = useState(false);
  const [is_verifyOTP, setIs_VerifyOTP] = useState(false);
  const [is_resetPassword, setIs_ResetPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [passwordData, setPasswordData] = useState({
    password: "",
    rePassword: "",
  });

  useEffect(() => {
    const savedEmail = localStorage.getItem("email");
    const savedPassword = localStorage.getItem("password");
    const rememberMe = localStorage.getItem("rememberMe") === "true";

    if (rememberMe) {
      setFormData({ email: savedEmail || "", password: savedPassword || "" });
      setEnabled(true);
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (enabled) {
      localStorage.setItem("email", formData.email);
      localStorage.setItem("password", formData.password);
      localStorage.setItem("rememberMe", true);
    } else {
      localStorage.removeItem("email");
      localStorage.removeItem("password");
      localStorage.setItem("rememberMe", false);
    }
    const res = await loginUser(formData);
    console.log(res);
    localStorage.setItem("Token", res.token);
    console.log(localStorage.getItem("Token"));
    localStorage.setItem("TokenExpiry", res.tokenExpiry);
    console.log(localStorage.getItem("TokenExpiry"));
    localStorage.setItem("UserName", res.userName);
    localStorage.setItem("UserEmail", res.userEmail);
    localStorage.setItem("userMobile", res.userMobile);

    if (res.hasError) {
      return toast.error(res.message);
    } else {
      navigate("/dashboard");
    }
  };

  const forgorPassword = async () => {
    setIs_login(false);
    setIs_GetOTP(true);
  };

  const getOtp = async (e) => {
    e.preventDefault();
    const res = await getForgotPasswordOtp({ email: formData.email });
    if (res.hasError) {
      toast.error(res.message);
    } else {
      toast.success(res.message);
      console.log(res);
      setToken(res.token);
      setIs_GetOTP(false);
      setIs_VerifyOTP(true);
    }
  };

  const resendOTP = async () => {
    const res = await resendOtp({ email: formData.email });
    console.log(res);
    if (res.hasError) {
      return toast.error(res.message);
    } else {
      toast.success("OTP resend.");
    }
  };

  const [otp, setOtp] = useState("");

  const OtpChange = (e) => {
    setOtp(e.target.value);
  };

  const verifyOTP = async (e) => {
    e.preventDefault();
    const otpValue = otp;
    if (otpValue !== "" && otpValue.length === 6) {
      const res = await verifyForgotPasswordOtp(
        {
          password_reset_otp: otpValue,
        },
        token
      );
      console.log(res);
      if (res.hasError) {
        return toast.error(res.message);
      } else {
        setIs_VerifyOTP(false);
        setIs_ResetPassword(true);
      }
    } else {
      toast.error("Please provide OTP.");
    }
  };

  const passwordChange = (e) => {
    const { name, value } = e.target;
    setPasswordData({
      ...passwordData,
      [name]: value,
    });
  };

  const passwordReset = async (e) => {
    e.preventDefault();
    const validate = validatePassword(passwordData.password);

    if (!validate)
      return toast.error(
        "Password must contain at least 1 uppercase, 1 special character, and 1 digit, with a minimum length of 8 characters."
      );

    if (passwordData.rePassword !== passwordData.password)
      return toast.error("passwords doesn't match");

    const res = await resetPassword({ password: passwordData.password }, token);
    console.log(res);
    if (res.hasError) {
      return toast.error(res.message);
    } else {
      setIs_ResetPassword(false);
      setIs_login(true);
    }
  };

  const validatePassword = (value) => {
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (!regex.test(value)) {
      return false;
    } else {
      return true;
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <AuthLayout uniqueKey="signin">
      {is_login && (
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-4" onSubmit={handleSubmit} method="POST">
            <div className="mt-2 relative">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                value={formData.email}
                onChange={handleChange}
                className="block w-full px-[16px] py-1.5 text-gray-900 bg-white border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300 sm:text-sm sm:leading-6 peer"
                placeholder=" "
              />
              <label
                htmlFor="email"
                className={`absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-500 transition-all duration-200 ease-in-out
      ${formData.email ? "hidden" : "text-sm top-1/2"}
    `}
              >
                Email address
              </label>
            </div>

            <div className="mt-2 relative">
              <input
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                autoComplete="current-password"
                required
                value={formData.password}
                onChange={handleChange}
                className="block w-full px-[16px] py-1.5 text-gray-900 bg-white border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300 sm:text-sm sm:leading-6 peer"
                placeholder=" "
              />
              <label
                htmlFor="password"
                className={`absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-500 transition-all duration-200 ease-in-out
        ${formData.password ? "hidden" : "text-sm top-1/2"}
      `}
              >
                Password
              </label>
              <button
                type="button"
                className="absolute inset-y-0 right-0 pr-3 flex items-center"
                onClick={toggleShowPassword}
              >
                {showPassword ? (
                  <IoEyeOffOutline className="h-6 w-6 text-gray-600" />
                ) : (
                  <IoEyeOutline className="h-6 w-6 text-gray-600" />
                )}
              </button>
            </div>
            <div className="flex flex-row justify-between">
              <div>
                {/* <label className="flex items-center space-x-2 cursor-pointer">
                  <div
                    className={`relative inline-flex items-center h-6 rounded-full w-11 transition-colors duration-200 ease-in-out border border-black ${
                      enabled ? "bg-[#770737]" : "bg-transparent"
                    } `}
                  >
                    <input
                      type="checkbox"
                      className="absolute opacity-0 w-0 h-0"
                      checked={enabled}
                      onChange={() => setEnabled(!enabled)}
                    />
                    <span
                      className={`inline-block w-4 h-4 transform bg-[#770737] rounded-full transition-transform duration-200 ease-in-out ${
                        enabled
                          ? "translate-x-6 bg-[#F3E9E9] "
                          : "translate-x-1"
                      }`}
                    ></span>
                  </div>
                  <span className="text-black-400 font-[11px]">
                    Remember me
                  </span>
                </label> */}
              </div>

              <div className="text-sm mt-1">
                <a
                  href="#"
                  onClick={forgorPassword}
                  className="font-semibold text-600 cursor-pointer text-[#770737]"
                >
                  Forgot password?
                </a>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex mt-8 w-full justify-center rounded-md bg-[#770737] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Log In
              </button>
            </div>
          </form>
        </div>
      )}
      {is_GetOTP && (
        <div>
          <div className="mt-12 sm:mx-auto sm:w-full sm:max-w-sm">
            <h2 className="text-center text-[22px] font-sans font-bold font-semibold">
              Get OTP
            </h2>
            <h6 className="text-center text-[14px] font-sans font-normal">
              provide email where you want the code.
            </h6>
          </div>
          <div className="mt-8">
            <form id="otp-form" onSubmit={getOtp}>
              <div className="mt-2 relative">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  value={formData.email}
                  onChange={handleChange}
                  className="block w-full px-[16px] py-1.5 text-gray-900 bg-white border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300 sm:text-sm sm:leading-6 peer"
                  placeholder=" "
                />
                <label
                  htmlFor="email"
                  className={`absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-500 transition-all duration-200 ease-in-out
      ${formData.email ? "hidden" : "text-sm top-1/2"}
    `}
                >
                  Email address
                </label>
              </div>
              <div className="max-w-[160px] mx-auto mt-8">
                <button
                  type="submit"
                  className="flex mt-4 w-full justify-center rounded-md bg-[#770737] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#62052c] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {is_verifyOTP && (
        <div>
          <div className="mt-12 sm:mx-auto sm:w-full sm:max-w-sm">
            <h2 className="text-center text-[22px] font-sans font-bold font-semibold">
              Verify OTP
            </h2>
            <h6 className="text-center text-[14px] font-sans font-normal">
              We have sent a code to {formData.email}.
            </h6>
          </div>
          <div className="mt-4">
            <form id="otp-form" onSubmit={verifyOTP}>
              <div className="mt-2 relative">
                <input
                  id="otp"
                  name="otp"
                  type="text"
                  required
                  value={otp}
                  onChange={OtpChange}
                  className="block w-full px-[16px] py-1.5 text-gray-900 bg-white border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300 sm:text-sm sm:leading-6 peer"
                  placeholder=" "
                />
                <label
                  htmlFor="otp"
                  className={`absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-500 transition-all duration-200 ease-in-out
            ${otp ? "hidden" : "text-sm top-1/2"}
          `}
                >
                  OTP
                </label>
              </div>
              <div className="max-w-[160px] mx-auto mt-8">
                <button
                  type="submit"
                  className="flex mt-4 w-full justify-center rounded-md bg-[#770737] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#62052c] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Verify
                </button>
              </div>
            </form>
            <div className="max-w-[160px] mx-auto mt-4">
              <button
                onClick={resendOTP}
                className="flex mt-4 w-full justify-center rounded-md bg-[#F3E9E9] border-black border-2 text-[#770737] px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Resend
              </button>
            </div>
          </div>
        </div>
      )}
      {is_resetPassword && (
        <div>
          <div className="mt-12 sm:mx-auto sm:w-full sm:max-w-sm">
            <h2 className="text-center text-[22px] font-sans font-bold font-semibold">
              Reset Password
            </h2>
            <h6 className="text-center text-[14px] font-sans font-normal">
              Please enter new password.
            </h6>
          </div>
          <form onSubmit={passwordReset} method="POST">
            {/* Password Input */}
            <div className="mt-4 relative">
              <input
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                value={passwordData.password}
                onChange={passwordChange}
                autoComplete="new-password"
                required
                className="block w-full px-[16px] py-1.5 text-gray-900 bg-white border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300 sm:text-sm sm:leading-6 peer"
                placeholder=" "
              />
              <label
                htmlFor="password"
                className={`absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-500 transition-all duration-200 ease-in-out
            ${passwordData.password ? "hidden" : "text-sm top-1/2"}
          `}
              >
                Password
              </label>
              <button
                type="button"
                className="absolute inset-y-0 right-0 pr-3 flex items-center"
                onClick={toggleShowPassword}
              >
                {showPassword ? (
                  <IoEyeOffOutline className="h-6 w-6 text-gray-600" />
                ) : (
                  <IoEyeOutline className="h-6 w-6 text-gray-600" />
                )}
              </button>
            </div>

            {/* Confirm Password Input */}
            <div className="mt-4 relative">
              <input
                id="rePassword"
                name="rePassword"
                type={showConfirmPassword ? "text" : "password"}
                value={passwordData.rePassword}
                onChange={passwordChange}
                autoComplete="new-password"
                required
                className="block w-full px-[16px] py-1.5 text-gray-900 bg-white border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300 sm:text-sm sm:leading-6 peer"
                placeholder=" "
              />
              <label
                htmlFor="rePassword"
                className={`absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-500 transition-all duration-200 ease-in-out
            ${passwordData.rePassword ? "hidden" : "text-sm top-1/2"}
          `}
              >
                Confirm Password
              </label>
              <button
                type="button"
                className="absolute inset-y-0 right-0 pr-3 flex items-center"
                onClick={toggleShowConfirmPassword}
              >
                {showConfirmPassword ? (
                  <IoEyeOffOutline className="h-6 w-6 text-gray-600" />
                ) : (
                  <IoEyeOutline className="h-6 w-6 text-gray-600" />
                )}
              </button>
            </div>

            <div>
              <button
                type="submit"
                className="flex mt-4 w-full justify-center rounded-md bg-[#770737] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#62052c] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Reset Password
              </button>
            </div>
          </form>
        </div>
      )}
    </AuthLayout>
  );
};

export default SignIn;
