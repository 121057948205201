import React from "react";
import HeaderCommon from "../../components/Common/Header";
const Home = () => {
  return (
    <>
      <HeaderCommon />
      <div className="flex w-full h-[calc(100vh-110px)] justify-center items-center">
        <div>
          <div className="sm:mx-auto sm:w-full sm:max-w-lg">
            <h1 className="text-center sm:text-[42px] text-[32px] text-[#770737] font-sans font-bold font-semibold">
              Welcome to MulberryDB
            </h1>
            <h6 className="text-center sm:text-[16px] text-[12px] font-sans font-normal">
              Gateway to Seamless Server-less Vector Database Solutions!
            </h6>
          </div>
          <div className="sm:mt-8 sm:mx-auto sm:w-full sm:max-w-lg">
            <img
              fetchPriority="high"
              alt=""
              src="../../../../welcome.png"
            ></img>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
