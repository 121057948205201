import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "../pages/Auth/Home";
import SignIn from "../pages/Auth/SignIn";
import Register from "../pages/Auth/Register";
import Dashboard from "../pages/Dashboard/Dashboard";
import Payment from "../pages/Dashboard/Payment";
import PrivateLayout from "../components/Layouts/PrivateLayout";
import ProfileMenu from "../pages/Dashboard/ProfileMenu";
import Collection from "../pages/Dashboard/Collection";
import StripeReturn from "../pages/Dashboard/StripeReturn";
import CommingSoon from "../pages/Dashboard/ComingSoon";

const Routes = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/auth",
      children: [
        {
          path: "login",
          element: <SignIn />,
        },
        {
          path: "register",
          element: <Register />,
        },
      ],
    },
    {
      path: "/dashboard",
      element: (
        <PrivateLayout>
          <Dashboard />
        </PrivateLayout>
      ),
    },
    {
      path: "/payment",
      element: (
        <PrivateLayout>
          <Payment />
        </PrivateLayout>
      ),
    },
    {
      path: "/collection/:id",
      element: (
        <PrivateLayout>
          <Collection />
        </PrivateLayout>
      ),
    },
    {
      path: "/comingsoon",
      element: (
        <PrivateLayout>
          <CommingSoon />
        </PrivateLayout>
      ),
    },
    {
      path: "/comingsoon1",
      element: (
        <PrivateLayout>
          <CommingSoon />
        </PrivateLayout>
      ),
    },
    {
      path: "/comingsoon2",
      element: (
        <PrivateLayout>
          <CommingSoon />
        </PrivateLayout>
      ),
    },
    {
      path: "/comingsoon3",
      element: (
        <PrivateLayout>
          <CommingSoon />
        </PrivateLayout>
      ),
    },
    {
      path: "/comingsoon4",
      element: (
        <PrivateLayout>
          <CommingSoon />
        </PrivateLayout>
      ),
    },
    {
      children: [
        {
          path: "profileMenu",
          element: (
            <PrivateLayout>
              <ProfileMenu />
            </PrivateLayout>
          ),
        },
      ],
    },
    {
      path: "/stripereturn/:checkoutSessionId",
      element: (
        <PrivateLayout>
          <StripeReturn />
        </PrivateLayout>
      ),
    },
  ]);

  return <RouterProvider router={router} />;
};

export default Routes;
