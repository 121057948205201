import React from "react";
import { Link } from "react-router-dom";

const AuthLayout = ({ children, uniqueKey }) => {
  return (
    <div className="flex h-[97vh] px-2 flex-col items-center">
      <div className="mx-auto my-auto shadow-custom-black-25 flex items-center sm:w-[800px] w-full h-[573px] rounded-3xl place-self-center self-center">
        <div className="sm:flex hidden justify-center items-center w-5/12 bg-[#770737] h-full rounded-l-3xl">
          <img
            fetchPriority="high"
            alt=""
            className="w-[261px] h-[246px]"
            src="../../../../sign-up.png"
          ></img>
        </div>

        <div className="flex flex-col py-12 sm:px-20 px-5 bg-[#F3E9E9] h-full sm:w-7/12 w-full sm:rounded-r-3xl">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <h2 className="text-center text-[22px] font-sans font-bold font-semibold">
              {uniqueKey === "register"
                ? "Get Started with Mulberry DB"
                : "Welcome to Mulberry DB"}
            </h2>
            <h6 className="text-center text-[16px] font-sans font-normal">
              {uniqueKey === "register"
                ? "Create a new account. Quick and easy"
                : "Login into your account"}
            </h6>
          </div>

          <div className="flex flex-row justify-center mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <h2 className="text-[16px] font-bold font-sans font-semibold">
              {uniqueKey === "register"
                ? "Already have an account?"
                : "Dont have an account?"}
            </h2>
            {uniqueKey === "register" ? (
              <Link to="/auth/login">
                <h2 className="ml-2 text-[16px] font-bold font-sans text-[#770737] cursor-pointer">
                  Sign In
                </h2>
              </Link>
            ) : (
              <Link to="/auth/register">
                <h2 className="ml-2 text-[16px] font-bold font-sans text-[#770737] cursor-pointer">
                  Sign Up
                </h2>
              </Link>
            )}
          </div>

          <div className="mt-4 sm:mx-auto w-full sm:max-w-sm">
            <div className="border-t border-gray-400 mx-auto"></div>
          </div>

          {children}
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
