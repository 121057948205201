import "./App.css";
import Routes from "./Routes";
import { Toaster } from "react-hot-toast";
import { ChakraProvider } from "@chakra-ui/react";
import mainTheme from "./mainTheme";

function App() {
  return (
    <ChakraProvider theme={mainTheme}>
      <Toaster position="top-center" reverseOrder={false} />
      <Routes />
    </ChakraProvider>
  );
}

export default App;
